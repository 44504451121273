import React from 'react'
import { useState } from 'react';

const Synergydatatile4= () => {
  const prices = ['£20-£80', '£100-£200','£200-£400','£400-£500','£500',];
  const [selectedOptionsprice, setSelectedOptionsprice] = useState([]);

  const handleCheckboxChangeprice = (price) => {
    if (selectedOptionsprice.includes(price)) {
      setSelectedOptionsprice(selectedOptionsprice.filter((selected) => selected !== price));
    } else {
      setSelectedOptionsprice([...selectedOptionsprice, price]);
    }
  };
 //   console.log(selectedOptionsprice)
  // <p>Selected Options: {selectedOptionsprice.join(', ')}</p>
  return (
    <div className='synergydatatile1'>
       <div className="synergydataleft">
        <img className='Sidebar4' src = "../synergy/Sidebar4.svg" alt = " " />
       </div>
      <div className="synergydataright">
      <div className="arrayofline">
          <div className="line1synergyyes"> </div>
          <div className="line1synergyyes"> </div>
          <div className="line1synergyyes"> </div>
          <div className="line1synergyyes"> </div>
          <div className="line1synergy"></div>


        </div>
        <div className="synergyrightfind">FIND YOUR SYNERGY</div>
        <div className="synergytell">What is the price range of your product ?</div>
     

      <div className='fourtsynergyoption'>
 
      {prices.map((price, index) => (
        <label
          key={index}
          className={`checkbox-container ${selectedOptionsprice.includes(price) ? 'selected' : ''}`}
        >
          <input
            type="checkbox"
            checked={selectedOptionsprice.includes(price)}
            onChange={() => handleCheckboxChangeprice(price)}
          />
          {price}
        </label>
      ))}
     
    </div>


    </div>

    </div>
  )
}

export default Synergydatatile4
