import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react';

export class Mapcheckout extends Component {

  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    latitude: 0,
    longitude: 0,
    center: { lat: 51.5072 , lng: 0.1276 }, // Initial default center
  };

  componentDidUpdate(prevProps) {
    if (prevProps.addressMatch !== this.props.addressMatch) {
      const [fullAddress, address, latitude, longitude] = this.props.addressMatch;

      // Check if latitude and longitude are valid numbers
      if (!isNaN(parseFloat(latitude)) && !isNaN(parseFloat(longitude))) {
        this.setState({
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
          center: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
          loading: false,
        });

        // Console log the addressMatch
    //    console.log('Updated addressMatch:', this.props.addressMatch);
      } else {
     //   console.error('Invalid latitude or longitude:', latitude, longitude);
      }
    }
  }

  onMarkerClick = (props, marker) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  render() {
    const mapStyles = {
      width: '749px',
      height: '237px',
      borderRadius: '10px',
      overflow: 'hidden',
    };

    const containerStyle = {
      position: 'relative',
      width: '1100px',
      height: '100%',
    };

    const { latitude, longitude, center } = this.state;

    return (
      <Map
        containerStyle={containerStyle}
        google={this.props.google}
        zoom={15}
        style={mapStyles}
        initialCenter={center} // Set initialCenter instead of center
        center={center} // Use center for subsequent updates
        onClick={this.onMapClicked}
      >
        <Marker
          onClick={this.onMarkerClick}
          position={{ lat: latitude, lng: longitude }}
          name={'Exact location provided after booking'}
        >
          <h3 style={{ backgroundColor: 'black', padding: '5px', borderRadius: '5px', margin: '0' }}>
            {this.state.selectedPlace.name}
          </h3>
        </Marker>

        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
        >
          <div>
            <h3>{this.state.selectedPlace.name}</h3>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyByI1MXrBW50io37CBScK-6Qk4b8WksZZc', 
})(Mapcheckout);
