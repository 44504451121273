import React, { useEffect , useState  ,useCallback} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import "./Calculator.jsx"
import "./Form2.scss" 
import Calculator from './Calculator.jsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDropzone } from 'react-dropzone';
import AWS from 'aws-sdk';
const Form2 = (props) => {
  const storeId = localStorage.getItem('register_store')
  // console.log(storeId)
                            const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT; 
                            const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
                            const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
  
                            const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
                            const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
                            const handleSaveClick = async () => {
                            try {
                                 await new Promise((resolve) => setTimeout(resolve, 1000));
                                 setIsSaveButtonClicked(true);
                                 toast.success('Data saved successfully!');
                                 setIsNextButtonDisabled(false);
                                  } catch (error) {
                                 console.error('Save error:', error);
                                  }    };
                                 const handleNextClick = () => {
                                 if (!isSaveButtonClicked) {
                                 toast.error('Please click "Save" before proceeding to "Submit & Next".');
                                 } else {
                                 toast.info('Proceeding to the next step.');
                                 } };
                    const [calculatorVisible, setCalculatorVisible] = useState(false);
                    const toggleCalculator = () => {
                    setCalculatorVisible((prevVisible) => !prevVisible);
                    };

                    const [uploadStatus, setUploadStatus] = useState('');
                    const [newImageUrls, setNewImageUrls] = useState([]);
                  
                    const onDrop = useCallback(async (acceptedFiles) => {
                      const s3 = new AWS.S3({
                        region: 'us-east-1',
                        accessKeyId: accessKeyId,
                        secretAccessKey: secretAccessKey ,
                      });
                  
                  
                      try {
                        const uploadPromises = acceptedFiles.map(async (file) => {
                          const params = {
                            Bucket: 'imageofstore',
                            Key: file.name,
                            Body: file,
                            ACL: 'public-read', // Adjust the ACL based on your requirements
                          };
                  
                          const response = await s3.upload(params).promise();
                    //      console.log('Upload response:', response);
                  
                          // Extract the URL from the response and log it
                          const imageUrl = response.Location;
                    //      console.log('Image URL:', imageUrl);
                  
                          return imageUrl;
                        });
                  
                        const newImageUrls = await Promise.all(uploadPromises);
                  
                   //     console.log('All images uploaded:', newImageUrls);
                        setNewImageUrls(newImageUrls);
                        setUploadStatus('Upload successful!');
                      } catch (error) {
                        console.error('Upload error:', error);
                        setUploadStatus('Upload failed.');
                      }
                    }, []);
                  
                    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: true });




                
             
                 const [selectedType, setSelectedType] = useState([]);
                 const Types = ['Rack','Shelf'];
                 const handlestoreTypeChange = (event) => {
                  setSelectedType(event.target.value);      
                };
         const idFromPropsOrStorage = props.storeId || localStorage.getItem('register_store');
         const [formData, setFormData] = useState({
           id: idFromPropsOrStorage || "",
           shelfType: '',
           shelfName: '',
           shelfArea : "" ,
           shelfRent: '',
           shelfImage : newImageUrls ,
           shelfDescription: '',
         });
       
      useEffect(() => {
        setFormData((prevFormData) => ({ ...prevFormData, id: props.storeId || '' }));
      }, [props.storeId]);
      useEffect(() => {
        setFormData((prevFormData) => ({ ...prevFormData, id: idFromPropsOrStorage || '' }));
      }, [idFromPropsOrStorage]);
    
      useEffect(() => {
        const handleStorageChange = () => {
          const storeId = localStorage.getItem('register_store');
          setFormData((prevFormData) => ({ ...prevFormData, storeId: storeId || '' }));
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
          window.removeEventListener('storage', handleStorageChange);
        };
      }, [newImageUrls]);

      const handleSubmit7 = async (e) => {
        e.preventDefault();
    
        try {
          // Make a POST request to the GraphQL endpoint
          const storeIdAsLong = parseInt(formData.storeId, 10);
          const response = await axios.post(graphqlEndpoint, {
            query: `
              mutation storeShelfInformation(
                $id: Long!,
                $shelfType: String!,
                $shelfName: String!,
                $shelfRent: String!,
                $shelfArea : Long! ,
                $shelfDescription: String!,
                $shelfImage  : [String] ! ,
             
              ) {
                storeShelfInformation(
                  id: $id,
                  shelfType: $shelfType,
                  shelfRent : $shelfRent , 
                  shelfName : $shelfName ,
                  shelfArea  : $shelfArea  ,
                  shelfDescription : $shelfDescription
                  shelfImage  :$shelfImage , 
    
    
                
                )
              }
            `,
            variables: {
              id: parseInt(formData.id), 
              shelfType: formData.shelfType,
              shelfRent: formData.shelfRent  , 
              shelfName: formData.shelfName ,
              shelfArea :  formData.shelfArea ,
              shelfDescription: formData.shelfDescription ,
              shelfImage : newImageUrls ,
         
            },
          });
 
     //     console.log(response)
     //     console.log(response.data);
      //    console.log(localStorage)
    
          setFormData({
            id: props.storeId  || "" ,
        shelfType: '',
        shelfName: '', 
        shelfArea : '' ,
        shelfRent: '',
        shelfDescription : ''
          });
// window.location.reload();
        } 
        catch (error) {
          // Handle errors
          console.error(error);
        }
      };



  return (
 
      <div className='selfform1'> 
 <form onSubmit={handleSubmit7}>
      <div className='selfform'> 
        <div className="selfdetailslist">
    
            <div className="selfdetailhead">
                <div className="selfinfoheadbox">
                <div className="selfinfo">Shelf Information</div>
                <div className="selflistdesc">Tell us about your Self. These specifics will help us figure out the best clients for you.</div>
                </div>
            </div>
           
            <div className="selflistdata">
                    <div className="selfdataname">

                      <div className="areaandname">
                        
                      <div className="areaandname1">
            <div className="selfname1">Shelf name</div>       
              <div className="selftypefield"> <input
            id = "selfinputratefield"
          type="text"
          style={{ padding: '20px' }}
          placeholder='Rack-1'
          value={formData.shelfName}
          onChange={(e) => setFormData({ ...formData, shelfName: e.target.value })}
        />
        </div>
        </div>
        <div className="areaandname2">
        <div className="selfname1">Shelf Area</div>
        <div className="selftypefield"> <input
                            id = "selfinputratefield"
          type="text"
          style={{ padding: '20px'  , width : '300px'}}
          placeholder='sq feet'
          value={formData.shelfArea}
          onChange={(e) => setFormData({ ...formData, shelfArea: e.target.value })}
        />
        </div>
        </div>
        </div>

                       
                     </div>
    
                <div className="selfdatadesc">
                    <div className="selfdesc1">Shelf description</div>
                    <div className="selfdescfield">
                        <div className="selfinputdesc"> <input
          type="text"   placeholder='Shelf - details '
          id = "selfdescfield"
          style={{ padding: '20px' }}
          value={formData.shelfDescription}
          onChange={(e) => setFormData({ ...formData, shelfDescription: e.target.value })}
        /></div>
                    </div>
                </div>
                <div className="selftyperent">
                <div className="selfdatatype">
                <div className="selftype1">Shelf Type </div>
                        <div className="selftypefield">
                            <div className="selfinputtype"> 
                               <select
                                   value={selectedType || formData.shelfType} // Use a default value if needed
                                   onChange={(e) => {
                                    setSelectedType() ;
                                    setFormData({ ...formData, shelfType: e.target.value }); 
                                    }}
                                   
                                
                                   id  ="selftypefield"  >


                                 <option value=""  >Select an option...</option>
                                 {Types.map((Type, index) => (
                                 <option key={index} value={Type}>
                                  {Type}
                                 </option>        
                                 ))}        
                                </select>
        
        
        
        
        </div>
                        </div>

                    </div>
                    <div className="selfrateperday">
                    <div className="selfdatarate">Shelf Rent(£) / Day</div>
                    <div className= "selfinputratefield">
   
    <div className="ratevalueinput"> <input
    id = "selfinputratefield"
          type="text"
          style={{ padding: '20px' }}
          placeholder='30 (only the value without £ sign )  '
          value={formData.shelfRent}
          onChange={(e) => setFormData({ ...formData, shelfRent: e.target.value })}
        />  </div>   
          
           <div className='know_calculate'> know about how to calculate per day rate of your shelf  ?
        <div className="logo-button" onClick={toggleCalculator}>
    {/*<img src= "/calculatoropener.svg"  alt =" "/> {*/}
    click me
        </div>
      
      </div>
            
      
    
          
    </div>
                    
                    </div>
                   
       
                </div>
                 </div>
         
                 
           
            </div>
    
            {calculatorVisible && <Calculator />}
    
                <div className="selfimgagebox">
    
                <div className="selfimgbox">
                <div className="selfimgehead">Shelf Images</div>
                <div className="selfimageheaddesc">Tell us about your Store. These specifics will help us figure out the best clients for you. </div>
                </div>
                <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
          <input {...getInputProps()} />
                           <div className="selfuploadbox2">
                         <div className="selfimgupload">
                            <img className="selfimguploadname" alt="" src="https://i.postimg.cc/BQ7jZ1hX/Frame-3508.png"/>
                         </div>
                         <div className="selfuploaddesc">
                                  <span className='selfuploadclickme'>Click here</span>
                                  <span className="selfuploadinstr"> to upload or drop media here</span>
                            </div>
                             </div>
                            
                            
                                
                            </div>
                            <div className='statusofimageload'>Loading...... {uploadStatus}</div>
                           <div className="uploadinstruction" style={{marginLeft : "270px"}}>Please wait untill Loading will successful </div>


                            <div className="uploadimghomecoverself">
                            
                            
                             <div className="buttonk">
                             <button type="submit" id="button" onClick={handleSaveClick}>
                             Save
                             </button>
                             </div>


                             <div className="button" > 
                             <Link  to={isSaveButtonClicked ? "/Listyourshelf" : ""}  id = "textdeco" onClick={handleNextClick}>
                             <div className={`confirmhomeself ${isNextButtonDisabled ? 'disabled' : ''}`}>
                             <div className="summitandsaveself">Submit & Next</div>  
                              </div>
                              </Link>
                              </div>

                              </div>



                          
                           
                            </div>
                             </div>
                             </form>
                               </div>
      )
    } ;
    


export default Form2
