import React, {useState} from 'react'
import "./Slider.css"
import { data } from './sliderData'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const Slider = () => {

  	return (

		<section className="slider-container">

		<div className="slider-content">
				<Swiper
					
					// updateOnWindowResize={false}
					spaceBetween={40}
					slidesPerView={3}
					centeredSlides={true}
					centeredSlidesBounds={true}
					setWrapperSize={true}
					breakpoints= {{
						320:{
							slidesPerView: 1,
							spaceBetween: 10,
							width: 250,
							centeredSlides: false,
							centeredSlidesBounds: false,
						},
						768:{
							slidesPerView: 2,
							spaceBetween: 20,
							centeredSlides: false,
							centeredSlidesBounds: false,
						},
						1024:{
							slidesPerView: 3,
							spaceBetween: 40,
							centeredSlides: false,
							centeredSlidesBounds: false,
						}
					}}

					className="why-join-us-slider"
					>
					{
						data.map((item) => (
							<SwiperSlide className="slider-card" id="slider-card" key={item.id}>
									<div className="slider-card-image">
										<img src={item.image} alt="" />
									</div>
									<div className="slider-card-title">
										{item.title}
									</div>
									<div className="slider-card-description">
										{item.description}
									</div>
							</SwiperSlide>
						))
					}
				</Swiper>

			</div>

		</section>
    		
	)
  }



export default Slider
