import React from 'react'
import Aboutgoal from '../../components/Aboutgoal/aboutgoal'
import Aboutbuilding from '../../components/Aboutbuilding/aboutbuilding'
import Aboutstatistics from '../../components/Aboutstatistics/aboutstatistics'
import Aboutteam from '../../components/Aboutteam/aboutteam'
import Aboutoptimise from '../../components/Aboutoptimise/aboutoptimise'
import Aboutomnichannel from '../../components/About/aboutomnichannel'

const Aboutus = () => {
  return (
    <div>
     <Aboutbuilding/>
     <Aboutgoal/>
     <Aboutomnichannel/>
     <Aboutstatistics />
     <Aboutteam/>
     <Aboutoptimise />
 
    </div>
  )
}

export default Aboutus
