import React from 'react';
import "./sdpTiming.scss";

const SdpTiming = ({ shelfData }) => {
  const { storeTiming } = shelfData;

  // Map day names to their corresponding placeholders
  const dayMap = {
    input1: "Monday",
    input2: "Tuesday",
    input3: "Wednesday",
    input4: "Thursday",
    input5: "Friday",
    input6: "Saturday",
    input7: "Sunday"
  };

  // Process storeTiming string
  if (typeof storeTiming === 'string') {
    // Use regular expression to extract input and value pairs
    const keyValuePairs = storeTiming.match(/(input[1-7]): (\d{2}:\d{2} \d{2}:\d{2})/g);

    // Check if keyValuePairs is an array
    if (keyValuePairs) {
      return (
        <div>
          <div className="businessinfo">
            {/* ... (existing code) ... */}
          </div>

          <div className="sdpTimingshopTimingsPerDayParent">
            <div className="sdpTimingshopTimingsPer1">Shop Timings (per day)</div>
            {["input1", "input2", "input3", "input4", "input5", "input6", "input7"].map((input, index) => {
              const pair = keyValuePairs.find(pair => pair.startsWith(`${input}:`)) || `${input}: `;
              const [day, value] = pair.split(': ');
              const mappedDay = dayMap[input] || day; // Use the mapped day name, or the original day if not found
              const displayValue = value.trim() === '' ? 'Closed' : value; // Display 'Closed' if the value is empty
              
              return (
                <div className="sdpTiminggroupParent" key={index}>
                  <div className="sdpTimingam600PmParent">
                    <div className="sdpTimingclosed">{displayValue}</div>
                    <div className="sdpTimingmonday">{mappedDay}</div>
                  </div>
                  <div className="sdpTiminggroupParent2">
                    <img className="sdpTiminggroupChild" alt="" src="https://i.postimg.cc/WbrbcjNC/Group-1171278826.png" />
                    <div className="sdpTimingt">{mappedDay[0]}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
    //   console.log('No valid input-value pairs found in storeTiming');
    }
  } else {
  //   console.log('storeTiming is not a string');
  }

  // Default rendering in case storeTiming is not a string or has no valid input-value pairs
  return (
    <div>
      <div className="sdpTimingshopTimingsPerDayParent">
        <div className="sdpTimingshopTimingsPer1">Shop Timings (per day)</div>
        {/* Display "Closed" for all days */}
        {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day, index) => {
          const mappedDay = dayMap[day] || day;
          return (
            <div className="sdpTiminggroupParent" key={index}>
              <div className="sdpTimingam600PmParent">
                <div className="sdpTimingclosed">Closed</div>
                <div className="sdpTimingmonday">{mappedDay}</div>
              </div>
              <div className="sdpTiminggroupParent2">
                <img className="sdpTiminggroupChild" alt="" src="https://i.postimg.cc/WbrbcjNC/Group-1171278826.png" />
                <div className="sdpTimingt">{mappedDay[0]}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SdpTiming;
