import React, { useState, useEffect } from 'react';
import { CREATE_USER_MUTATION } from "../AuthPage/createUserMutation";
import { SIGN_IN_USER_MUTATION } from "../AuthPage/signInUserMutation" ;
import { CREATE_USER_ECOMM } from '../AuthPage/ecomcreate';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import "./page.scss";
import { Link } from 'react-router-dom';


  const AuthPage2 = ({ setAuthenticated, setToken }) => {
  const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
  console.log('GraphQL Endpoint:', graphqlEndpoint);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [lastName , setlastName] =useState('')
  const [firstName , setfirstName] =useState('') ;
  const [role , setrole] =  useState('') ;
  const navigate = useNavigate();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  useEffect(() => {
    // Check if there is an authentication token in localStorage
    const storedToken = localStorage.getItem('authToken');
    const storedStoreId = localStorage.getItem('storeId');
    const ecomUser_id = sessionStorage.getItem('ecomUser_id')
    if ((storedToken && storedStoreId) || ecomUser_id ) {
      setAuthenticated(true);
      navigate(`/Browseshops/Sdp/${storedStoreId}`);
    }
  }, [setAuthenticated, navigate]);
  


  useEffect(() => {
    sessionStorage.setItem('role', role);
  }, [role]);

  // Update role state when input changes
  const handleRoleChange = (e) => {
    setrole(e.target.value);
  };



  const handleBothClicks = async () => {
    try {
      if (!email) {
        toast.error('Please enter your email.');
        return;
      }
      if (!firstName) {
        toast.error('Please enter your First Name.');
        return;
      }
      if (!lastName) {
        toast.error('Please enter your Last Name.');
        return;
      }
      if (!password || !isValidPassword(password)) {
        toast.error('Please enter a valid password with at least 7 characters, including a special character.');
        return;
      }
      if (!isCheckboxChecked) {
        toast.error('Please Accept Privacy & Policy.');
        return;
      }
      if (!role) {
        toast.error('Please select role.');
        return;
      }



        const variables = {
        userEmail: email,
        userPassword: password,
        lastName: lastName,  // Assuming you have `lastName` variable
        firstName: firstName  ,// Assuming you have `firstName` variable
        role :  role ,
         };
      
    
       const check_role = sessionStorage.getItem("role");
       let mutation;
       if (check_role === "ADMIN") {
         mutation = CREATE_USER_MUTATION;
       } else if (check_role === "ECOMM") {
         mutation = CREATE_USER_ECOMM;
       }
      
     

const response = await fetch(graphqlEndpoint, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  body: JSON.stringify({
    query:mutation, // Use the selected mutation
    variables,
  }),
});
      const data = await response.json();
      let responsefromlogin;
     // const check_role = localStorage.getItem("role");
      if (check_role === "ADMIN") {
        responsefromlogin = data.data.createUser;
      } else if (check_role === "ECOMM") {
        responsefromlogin = data.data.createEcommUser;
      }
      const storedStoreId = localStorage.getItem('storeId');
      if (responsefromlogin) {
        toast.success('Account created successfully! Now sign in.');
        navigate(`/Browseshops/Sdp/${storedStoreId}`);
      }
    } 
    catch (error) {
      console.error('Sign up error:', error);
    }

  };


  const handleSignIn = async () => {
    try {
      if (!email) {
        toast.error('Please enter your email.');
        return;
      }
      if (!password) {
        toast.error('Please enter your password.');
        return;
      }


      const variables = { userEmail: email, userPassword: password  };
      const response = await fetch(graphqlEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          query: SIGN_IN_USER_MUTATION,
          variables,
        }),
      });

                 const data = await response.json();
                 if (data.data.signInUser) {
                  console.log("data is " ,data.data.signInUser )
                 setAuthenticated(true);
               
                  console.log(data)
                // sessionStorage.setItem('ecomUser_id' , ecomUser_id) ;
                 const token = data.data.signInUser.token;
           
                 if (data.data && data.data.signInUser && data.data.signInUser.users ) {
                 const registerStores = data.data.signInUser.users.registerStores;
                  const role = data.data.signInUser.users.UserRole ;
                 localStorage.setItem('register_store' , registerStores);
                 localStorage.setItem('role' ,role )
                 
                 for (const store of registerStores) {
                 const storeId = store.storeId;
             
                 }
                 } else {
                 console.error('Invalid response structure:', data);
                 }

                 if (data.data.signInUser.ecommUser !== null) {
                  const ecomUser_id = data.data.signInUser.ecommUser.ecomUser_id;
                  const FirstName = data.data.signInUser.ecommUser.FirstName ;
                  const lastName = data.data.signInUser.ecommUser.lastName ;
                  const  EcomUserEmail = data.data.signInUser.ecommUser.EcomUserEmail ;
                  const role = data.data.signInUser.ecommUser.EcomUserRole ;
                 localStorage.setItem('role',role)
                  sessionStorage.setItem('ecomUser_id', ecomUser_id);
                  sessionStorage.setItem('EcomUserEmail',EcomUserEmail) ;
                  sessionStorage.setItem('user_name1', FirstName ) ;
                  sessionStorage.setItem('user_name2', lastName );
                }
                
                 
      const FirstName = data.data.signInUser.users.FirstName ;
      const lastName = data.data.signInUser.users.lastName ;
 
    
     
        localStorage.setItem('user_email', email);
        localStorage.setItem('authToken', token);
        localStorage.setItem('user_name1', FirstName ) ;
        localStorage.setItem('user_name2', lastName );
   
     
      //localStorage.setItem('ecomUser_id' , ecomUser_id) ;
console.log(data)
      console.log(email)
        console.log(token)
        setToken(token);
        const storedStoreId = localStorage.getItem('storeId');
        navigate(`/Browseshops/Sdp/${storedStoreId}`);
        
      } {/*} else {
        toast.error('Invalid email or password. Please try again.');
      }{*/}
    } catch (error) {
      console.error('Sign in error:', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
  
    localStorage.removeItem('user_email'); 
    
    localStorage.removeItem('register_store') ;
    localStorage.removeItem('user_name1');
    localStorage.removeItem('user_name2');
    localStorage.removeItem('role')
  
    setAuthenticated(false);
    navigate("/");
  };

  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,}$/;
    return passwordRegex.test(password);
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const [activePage, setActivePage] = useState('login');

  const handleButtonClick = (page) => {
    setActivePage(page);
    };

    
  const CombinedClickHandler = () => {
    handleBothClicks();
    handleSignIn();
  };
 
  return (
    <div>
                 <div className="logloginChild" />
                 <div className="loggroupParent">
                 <div className="logframeWrapper">
                 <div className="logframeParent">

                 {activePage === 'login' ? (
                 <div className="logloginYourAccountParent">
                 <div className="logloginYourAccount1" onClick={() => handleButtonClick('login')} >Login your account</div>
                 <div className="loghaveAnAccountLogin1">
                 <div className="lognewToThirdretailContainer">
                 <span className='newtoretail'>New to ThirdRetail? </span>
                 <span
                         className="logcreateYourAccount"   style={{cursor: 'pointer' }} onClick={() => handleButtonClick('createAccount')}
                  >Create your Account</span>
                 </div>
                 </div>
                </div>
  ) : null}
  {activePage === 'createAccount' ? (
                <div className="logloginYourAccountParent">
                 <div className="logloginYourAccount1" >Create Your account</div>
                 <div className="loghaveAnAccountLogin1">
                 <div className="lognewToThirdretailContainer">
                 <span className='newtoretail'>Already have account?  </span>
                 <span
                         className="logcreateYourAccount"   style={{cursor: 'pointer'  }}  onClick={() => handleButtonClick('login')}
                  >Log in </span>
                 </div>
                 </div>
                </div>
) : null}


      <div className="logframeGroup">
       {activePage === 'login' ? (
        <div className='loginformauth'>

                <div className="loginput1">
                <div className='Authheadlinefield'>Email</div>
                <input type="text" 
                placeholder="Email" 
                id = "loginput1"
                value={email} onChange={(e) => setEmail(e.target.value)}  required />
                </div>


             


                <div className="loginput2">
                <div className='Authheadlinefield'>Password</div>
                <input type = { showPassword ? 'text' : 'password'} placeholder="Password" 
                id  = "loginput2"     
                value={password} onChange={(e) => setPassword(e.target.value)} required />
                <img
                className="eye-icon1"
                src={showPassword ? '/openeye.png' : 'closeeye.png'}
                alt={showPassword ? 'Open eye' : 'Closed eye'}
                onClick={handleTogglePassword}
                /> 
                </div>
               
                <div className='signinbutton'> <button id ="signinbutton" onClick={handleSignIn}>Log in</button></div>
                <div className="logforgetYourPassword1">
                  Forget your password?
                </div>
                 </div>
                 
      ) : null}
      {activePage === 'createAccount' ? (
                <div className='loginformauth'>
                 
                 <div className="loginput1">
                 <div className='Authheadlinefield'>Email</div>
                 <input type="text" 
                  placeholder=" Enter your Email" 
                  id = "loginput1"
                  value={email} onChange={(e) => setEmail(e.target.value)}  required />
                 </div>

               <div className='loginputname'>

              <div className="loginput09">
              <div className='Authheadlinefield'>last Name</div>
                <input type="text" 
                placeholder="Enter your Last Name" 
                id = "loginput09"
                value={lastName} onChange={(e) => setlastName(e.target.value)}  required />
                </div>


                 <div className="loginput09">
                 <div className='Authheadlinefield'>First Name</div>
                <input type="text" 
                placeholder="Enter your First Name" 
                id = "loginput09"
                value={firstName} onChange={(e) => setfirstName(e.target.value)}  required />
                </div>
                </div>

                {/*}   defining role    {*/}
                <div className="loginput" >
      <div className='Authheadlinefield'>Role</div>
      <select
        value={role}
        id = "roleinput"
        onChange={handleRoleChange}
        required
        style={{
          padding: '12px',
          fontSize: '16px',
          borderRadius: '8px',
          backgroundColor: '#ffffff',
          border: '1px solid #9aa8bc',
          width: '100%',
        }}
      >
        <option value="">Select Role</option>
        <option value="ADMIN">ADMIN</option>
        <option value="ECOMM">ECOMM</option>
        </select>
        </div>

               
                 <div className="loginput2"> 
                 <div className='Authheadlinefield'>Password</div>

                 <input type = { showPassword ? 'text' : 'password'} 
                 placeholder=" Enter your Password" 
                 id  = "loginput2"  
                
                 value={password} onChange={(e) => setPassword(e.target.value)} required />
                 <img
                 className="eye-icon2"
                 src={showPassword ? '/openeye.png' : 'closeeye.png'}
                 alt={showPassword ? 'Open eye' : 'Closed eye'}
                 onClick={handleTogglePassword}
                /> 
                </div>
      
                <label className='checkboxandpolicy'>
                 <input
          type="checkbox"
         
          checked={isCheckboxChecked}
          onChange={handleCheckboxChange} required 
        /> <div className='agreementoncreateaccount'>
        By creating an account means you agree to the Terms and Conditions, & our Privacy Policy</div>
      </label>
            
           <div className='signupbutton'> <button id="signupbutton" onClick={CombinedClickHandler }  
       > Join ThirdRetail</button> </div>
        </div>
        
      ) : null}

<ToastContainer />
      </div>
      
    </div>
     
  </div>


    
  <Link to="/">
  <img className="loglogoIcon" alt="" src="/logo@2x.png" />
</Link>
 
</div>
<ToastContainer />



<div className="logsidePage1">
  <img className="logbkgIcon" alt="" src="/bkg@2x.png" />
  <div className="logbackground1" />
  <div className="loggroupDiv">
    <div className="logframeWrapper1">
      <div className="logoptimiseYourInStoreRevenueParent">
        <div
          className="logoptimiseYourInStore1"
        >Optimise your in-store revenue </div>
        <div className="logformDescription">
          <p className="logover500Retail">
          Improve your Instore profitability
          </p>
          <p className="logover500Retail">
            Get your space in front of them.
          </p>
        </div>
      </div>
    </div>
    <div className="loggroupParent1">
      <div className="logframeWrapper2">
        <div className="logyourStoreParent">
          <div className="logyourStore">Your Store</div>
          <div className="logimage2Wrapper">
            <img
              className="logimage2Icon"
              alt=""
              src="/image-2@2x.png"
            />
          </div>
        </div>
      </div>
      <div className="loggroupParent2">
        <img
          className="logframeChild"
          alt=""
          src="/group-1171278858@2x.png"
        />
        <div className="logframeWrapper3">
          <div className="loggroupParent3">
            <img
              className="logframeItem"
              alt=""
              src="/group-1171278826@2x.png"
            />
            <div className="logweeks">2 Weeks</div>
            <div className="logshelfLife">Shelf life </div>
          </div>
        </div>
        <div className="logtag">
          <img
            className="logcheckcircleIcon"
            alt=""
            src="/CheckCircle.png"
          />
          <div className="logmoneyTransferSuccessful1">
            Money Transfer Successful
          </div>
        </div>
      </div>
      <div className="loggroupParent4">
        <img
          className="logframeInner"
          alt=""
          src="/group-1171278859@2x.png"
        />
        <div className="logsectionWrapper">
          <div className="logsection">
            <div className="logparent">
              <div className="logdiv">3</div>
              <div className="logtotalShelvesRented1">
                Total Shelves Rented
              </div>
            </div>
          </div>
        </div>
        <div className="logsection1">
          <div className="logsectionInner">
            <div className="logframeWrapper4">
              <div className="logframeParent2">
                <div className="logsectionWrapper">
                  <div className="loggroup">
                    <div className="logdiv1">£245.00</div>
                    <div className="logtotalIncome">Total Income</div>
                  </div>
                </div>
                <img
                  className="logbarChart21Icon1"
                  alt=""
                  src="/barchart2-1@2x.png"
                />
              </div>
            </div>
          </div>
        </div>
        <img
          className="logframeChild1"
          alt=""
          src="/group-1171278858@2x.png"
        />
      </div>
    </div>
  </div>
</div>
</div>

  );
};

export default AuthPage2 ;
