import React, { useState } from 'react';
import "./calculator.scss"
const Calculator = () => {
  const [inputA, setInputA] = useState('option1'); // Initialize with a default option
  const [inputB, setInputB] = useState('option1');
  const [inputC, setInputC] = useState(0);
  const [inputD, setInputD] = useState(0);
  const [result, setResult] = useState(null);

  const handleCalculate = () => {
    const a = parseFloat(inputA === 'option1' ? 1.2 : inputA === 'option2' ? 1 : 0.8);
    const b = parseFloat(inputB === 'option1' ? 1.3 : inputB === 'option2' ? 1.2 : 1.0);
    const c = parseFloat(inputC);
    const d = parseFloat(inputD);

    if (!isNaN(a) && !isNaN(b) && !isNaN(c) && !isNaN(d)) {
      const calculatedResult = (a * b * c * d) / 30;
      setResult(calculatedResult);
    } else {
      setResult(null);
    }
  };

  return (
<div className="fullcal">
      <div className='calculatorhead' style={{color : "#1A212B"}}>Rent shelf per day estimate Calculator  </div>
    <div className="infocal">
    <div className='calculator'>
    
      <div className='contentchamberofcalculator'>
        <div>
        <label>
          <div className="lacandtile">
          <div className="titlecal" style={{color : "#1A212B"}}>Location-Factor:</div>
        <span className='optionlac'>
          <select
             id  = "optionlac"
             style={{ width: '150px' ,height: '30px' ,marginTop: '20px',backgroundColor: '#fffff' }}
            value={inputA}
            onChange={(e) => setInputA(e.target.value)}
          >
            <option value="option1">1.2</option>
            <option value="option2">1</option>
            <option value="option3">0.8</option>
          </select>
        
          </span>
          </div>
        </label>
      </div>
      <div>
        <label>
        <div className="lacandtile">
        <div className="titlecal" style={{color : "#1A212B"}}>Shelf-Factor:</div>
        <span className='optionlac'>
          <select
          style={{ width: '150px' ,height: '30px' ,marginTop: '20px' ,backgroundColor: '#fffff' }}
            value={inputB}
            id  = "optionlac"
            onChange={(e) => setInputB(e.target.value)}
          >
            <option value="option1">1.3</option>
            <option value="option2">1.2</option>
            <option value="option3">1.0</option>
          </select>
          </span>
        </div>
        </label>
        </div>
      <div>
        <label>

        <div className="titlecalinput" style={{color : "#1A212B"}}>Sample Base Rate          <span className="caldisc">(in Pounds  £):</span></div>  

          <input
            type="number"
            style={{ width: '300px', height: '40px' ,marginTop: '10px',backgroundColor: '#fffff' , borderRadius: '8px',}}
            value={inputC}
            id  = "calbox"
            onChange={(e) => setInputC(e.target.value)}
          />
        </label>
        <label>
        <div className="titlecalinput" style={{color : "#1A212B"}}> Linear feet Length :  </div> 

          <input
            type="number"
            style={{ width: '300px', height: '40px' ,marginTop: '10px',backgroundColor: '#fffff' , borderRadius: '8px',}}
            value={inputD}
            id  = "calbox"
            onChange={(e) => setInputD(e.target.value)}
          />
        </label>
      </div>
      <div className='calbutton'    onClick={handleCalculate}>Calculate</div>
      {result !== null && (
        <div>
          <div className='resultcal'style={{color : "#1A212B"}}>Result:(Shelf Rent(£) / Day)</div>
          <p className='rateoutput'style={{color : "#1A212B"}}>per day Rate : {result} (paste this value)</p>
        </div>
      )}
    </div>
    </div>

    <div className="infocalsec">

   <div className="calmatrix"style={{color : "#1A212B"}}>Shelf Rental Rate Matrix:</div> 
   <div className="calmatrix"style={{color : "#1A212B"}}>Shelf Rental Rate Matrix:</div> 
   <div className="locationfactor"style={{color : "#1A212B"}}>Location Factor:</div>
   <div className="locationfactordetails">
   <div className="locdel"style={{color : "#1A212B"}}>  Premium (Front of store, counters, exits): 1.2</div>
   <div className="locdel"style={{color : "#1A212B"}}>Standard (Middle aisles): 1</div>
   <div className="locdel"style={{color : "#1A212B"}}>Economy (Back areas): 0.8</div>


</div>

<div className="shelffactor">


</div>
<div className="locationfactor"style={{color : "#1A212B"}}>Shelf Factor:</div>
   <div className="locationfactordetails">
   <div className="locdel"style={{color : "#1A212B"}}>  Top/Eye level: 1.3</div>
   <div className="locdel"style={{color : "#1A212B"}}>Middle: 1.2</div>
   <div className="locdel"style={{color : "#1A212B"}}>Bottom: 1.0</div>


</div>

<div className="locationfactor"style={{color : "#1A212B"}}>Sample Base Rate:
£150 per linear foot per month  <div className="basebracet"style={{color : "#1A212B"}}>(This is basically depend on your location of your store , so please estimate your rent accordingly)</div>
</div>
 <div className="locationfactor"style={{color : "#1A212B"}}>Sample Calculation:
10 linear feet premium shelf x Base Rate £150 x Location Factor 1.2 x Shelf Factor 1.3 / 30 days = £78 per day
</div>

    </div>




    </div>
    </div>
  );
};

export default Calculator;
