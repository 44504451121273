import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
   
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectCoverflow, Pagination, Navigation } from 'swiper';

import "./Whatpeoplesay.scss"

import {data} from "./data"


const Whatpeoplesay = () => {
  return (
            <section className="review-container">

                    <div className="review-headline-container">
                        <img className="review-icon" alt="" src="./what-people-say/comma.png" />
                        <div className="review-what-business-say-content">
                            <span>What businesses say</span>
                            <span>
                            <span className="review-about-us"> about us</span>
                                ...
                            </span>
                    </div>
                        
                </div>

                <div className="review-slider-container">

                <Swiper
                effect={'coverflow'}
                centeredSlides={true}
                // centeredSlidesBounds={true}
                loop={true}
            
                grabCursor={true}
                slidesPerView={3} 
                spaceBetween={0} 
                
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 2.5,
                    // slideShadows: false,
                }}

                breakpoints= {{
                    320:{
                        slidesPerView: 1,
                        spaceBetween: 0,
                    },
                    480:{
                        slidesPerView: 3,
                        spaceBetween: 0,
                    },
                    768:{
                        slidesPerView: 3,
                        spaceBetween: 0,
                    },
                    1024:{
                        slidesPerView: 3,
                        spaceBetween: 0,
                    }
                }}

                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                    clickable: true,
                }}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="swiper_container"
                >
                    {
                        data.map((item, index) => (

                            <SwiperSlide key={index}>

                                <div className="review-card">
                                        <div className="review-card-image">
                                            <img alt="" src={`./what-people-say/${item.img}`} />
                                        </div>
                                        <div className="review-card-content">
                                            <div className="review-card-text">
                                                <p>{item.review}</p>
                                            </div>
                                            <div className="review-card-content-author">
                                                {item.name}
                                            </div>
                                            <div className="review-card-content-company text-highlight">
                                                {item.company}
                                            </div>
                                        </div>
                                </div>
                
                            </SwiperSlide>
                        ))
                    }
        
                </Swiper>
                <div className="slider-controler">
                    <div className="swiper-button-prev slider-arrow">
                        <ion-icon name="arrow-back-outline"></ion-icon>
                    </div>
                    <div className="swiper-button-next slider-arrow">
                        <ion-icon name="arrow-forward-outline"></ion-icon>
                    </div>
                    <div className="swiper-pagination"></div>
                </div>
                </div>
            </section>
        );
      }
     





export default Whatpeoplesay 







