
export const data = [
    {
      id: 1,
      image: "HandCoins.svg",
      title: "Save on Ads spend, Boost Results",
      description: "Cut down on advertising costs while achieving effective customer acquisition through our budget-friendly offline shelf network",
    },
    {
      id: 2,
      image: "ChartPieSlice.svg",
      title: "Expand Smoothly, Sell More",
      description: "Fuel your product launch or expansion with a hassle-free new sales channel – access our recommended shelves for tailored growth.",
    },
    {
      id: 3,
      image: "UsersFour.svg",
      title: "Bring Your Product Closer",
      description: "Put your product where it matters – local shelves. Bridge the gap between online presence and real-world touchpoints for a richer customer experience.",
    },
    {
      id: 4,
      image: "ChartLine.svg",
      title: "Smarter Operations, Bigger Wins",
      description: "Get smart insights to produce, market, and time your products effciently. Stay ahead with real-time data and market trends.",
    },
  ]