import React, { useEffect , useState } from 'react';
import "./Form1.scss" ;
import {Link}  from "react-router-dom" 
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Form1 = (props) => {

	         // handle condition for next and save
	        const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
	        const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
            const handleSaveClick = async () => {
		    try {
		     setIsSaveButtonClicked(true);
		     toast.success('Data saved successfully!');
		     setIsNextButtonDisabled(false); 
		     } catch (error) {
		     console.error('Save error:', error);   } };
             const handleNextClick = () => {
             if (!isSaveButtonClicked) {
             toast.error('Please click "Save" before proceeding to "Next".');
             } else {
             toast.info('Proceeding to the next step.');
                 } };  


	                   const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
	                   const [formData,   setFormData] =useState({storeName : '' , storeArea : "",  userId: localStorage.getItem('authToken') || '', selectCity : '' , selectCountry : '' , postalCode : '' ,  storeAddress : '' }) ;    
                       const [selectedCountry, setSelectedCountry] = useState('');
	                   const [selectedCity, setSelectedCity] = useState('');
                       const countries = ['United-Kingdom (default)'];
	                   const cities = ['London', 'Manchester', 'Glasgow','Birmingham','Brighton','Leeds','Liverpool','Yorkshire','Bath','EdinBurgh'];
	  
	                    useEffect(() => {
		                const handleStorageChange = () => {
		                const authToken = localStorage.getItem('authToken');
		                setFormData((prevFormData) => ({ ...prevFormData, userId: authToken || '' }));
		                 };
						 window.addEventListener('storage', handleStorageChange);
						 return () => {
						   window.removeEventListener('storage', handleStorageChange);
						 };
					   }, []);
						  	
	                                // Function to handle state selection
	                           const handleStateChange = (event) => {
			                   setSelectedCountry(event.target.value); 
			                    };
			                       // Function to handle city selection
			                    const handleCityChange = (event) => {
			                    setSelectedCity(event.target.value);
			                     };	
		
	   


                  const handleSubmit = async(e) => {
	              e.preventDefault() ;
	              try{
                  // Parse userId to Long type
                  const userIdAsLong = parseInt(formData.userId, 10);
		          const response = await axios.post(graphqlEndpoint, {
		     	  query : `
			      mutation RegisterStore($storeName:String! , 
                  $storeArea : Long! ,
				  $selectCity : String!  ,
				  $selectCountry :  String! , 
				  $postalCode :  String! , 
				  $storeAddress :  String! ,
				  $userId: Long !)  {
				  registerStore(storeName : $storeName  , storeArea: $storeArea ,  userId : $userId, selectCity : $selectCity , selectCountry : $selectCountry, postalCode :$postalCode,  storeAddress : $storeAddress)
			      } ` ,
			             variables : {
                        storeName : formData.storeName , 
						storeArea : formData.storeArea , 
	                    selectCity : formData.selectCity , 
	                    selectCountry : formData.selectCountry , 
	                    postalCode : formData.postalCode , 
	                    storeAddress : formData.storeAddress , 
	                    userId: userIdAsLong , 
		            	} ,	} ,		) ;

		                //  Handle the response data
		               //   console.log(response.data);
			          //    console.log(response.data.data.registerStore); // prints id
						const registerStore = response.data.data.registerStore
						localStorage.setItem('register_store', registerStore )
		                props.setStoreId(response.data.data.registerStore);
			            setFormData({storeName : '' ,  storeArea : '' , userId: props.token || "" ,  selectCity : '' , selectCountry : '' , postalCode : '' ,  storeAddress : ''}) ;
                           }
	                    catch (error){
		                console.log(error) ;
	                     } } ;
                         return (



          <div className='form1'>
          <div className="fullfirstblock1">
          <div className="fullfirstblock">
          <div className="form1firstblock">
          <div className="storeinfotitle">Store Information</div>
          <div className="businesstellUsAbout1">
            Tell us about your Store. These specifics will help us figure out the best clients for you
          </div>
          </div>
  
        <div className="form">
	 
          <div className="textField">
		  <form onSubmit={handleSubmit}> 
        				<div className="labelWrapper">
          			   <div className="label">Store name</div>
        				</div>
           <div className="textField">
		<input
          type="text"
		  id = "input"
		  placeholder="Enter store name"
          value={formData.storeName}
          onChange={(e) => setFormData({ ...formData, storeName: e.target.value })}
        />
           </div>
           
           


      			<div className="selectFieldParent">

  

				  <div className="selectField">
              <div className="labelWrapper">
                <div className="label">Select Country</div>
              </div>
            
                <select
                    value={selectedCountry || formData.selectCountry} // Use a default value if needed
					onChange={(e) => {
					  setSelectedCountry() ;
					  setFormData({ ...formData, selectCountry: e.target.value });
					}}
					className="input1"
                >
                  <option value="" disabled>Select an option...</option>
                  {countries.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
               
             
            </div>


						<div className="selectField">
                        <div className="labelWrapper">
                        <div className="label">Select city</div>
                        </div>
     
	  <select
                    value={selectedCity || formData.selectCity} // Use a default value if needed
					onChange={(e) => {
					  setSelectedCity() ;
					  setFormData({ ...formData, selectCity: e.target.value });
					}}
					className="input1"
                >
          <option value="" disabled>Select an option...</option>
          {cities.map((city, index) => (
            <option key={index} value={city}>
              {city}
            </option>
          ))}
        </select>
     </div>
   			</div>
      			<div className="textArea">
        				<div className="labelWrapper">
          					<div className="label">Store address</div>
        				</div>

						<input
          type="text"
		  id ="input3" 
          value={formData.storeAddress}
          onChange={(e) => setFormData({ ...formData, storeAddress: e.target.value })}
        />
      			</div>

<div className="areaandcode">
      			<div className="selectField">
                        <div className="labelWrapper">
						<div className="label">PostalCode</div>
            						
          					</div>
							  <input
          type="text"
		  id = "input"
          value={formData.postalCode}
          onChange={(e) => setFormData({ ...formData, postalCode: e.target.value })}
        />
        				</div>
						

						<div className="selectField">
                        <div className="labelWrapper">
						<div className="label">Store Area</div>

					
            						
          					</div>
							  <input
          type="text"
		  id = "input"
          value={formData.storeArea}
          onChange={(e) => setFormData({ ...formData, storeArea: e.target.value })}
        />
        				</div>
						</div>

			
   {/*}
   
 			//(@GraphQLArgument(name = "storeName", description = "contains the name of store") String storeName,
			//	 @GraphQLArgument(name = "storeArea", description = "Area of the store")Long storeArea,


 
   <input 
   type = "text" 
   value  =  {formData.userId}
   onChange={(e) => setFormData({ ...formData, userId: e.target.value})}
		  />{*/}
      			  



					 <div className="linksummit">
                     <button type="submit"  id='buttonk' onClick={handleSaveClick}>
                      Save
                     </button>
                     <Link className={`linkform ${isNextButtonDisabled ? 'disabled' : ''}`}  to={isSaveButtonClicked ? "Form12" : ""} onClick={handleNextClick} style = {{textDecoration : "none"}}>
                      Next
                    </Link>
                    </div>



					
				</form>		
			 
</div>
</div>
</div>
    </div>


	  

	   </div>
  )
}

export default Form1 ;