import React from 'react'
import "./aboutomnichannel.scss"
import { Link } from 'react-router-dom';
const aboutomnichannel = () => {

          return (
                <div className="omnichannelgroupParent">
                      

                          <div className="omnicahnnelimg">
                          <img src ="./omnichannel.svg"  alt = " " />
                          </div>
                     
                           <div className="omnichannelframeGroup">

                                      <div className="omnichannelwereBuildingParent">
                                             <div className="omnichannelwereBuilding">WE'RE BUILDING</div>
                                             <div className="omnichannelaffordableOmnichannelExperieParent">
                                             <div className="omnichannelaffordableOmnichannelExperie">Affordable-omnichannel experience for your customers</div>
                                             <div className="omnichannelbutBeforeThat1">But before that lets understand your product & business </div>
                                            </div>
                                       </div>


                                   <div className="omnichannelbutton ">
                                   <Link className="omnichannellabel"  to="/Synergy">Find your Synergy</Link>
                                   <div className="omnichannelicon1">
                                        <img className="omnichannelicon2" alt="" src="omniarrow.svg" />
                                   </div>
                                   </div>

                           </div>
                </div>
    
  )
}

export default aboutomnichannel
