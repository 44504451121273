import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import "./Listyourshelf.scss"
//import Form13 from '../../components/Form1/Form13';
import {Link}  from "react-router-dom" 
const Listyourshelf = ({ handleLogout ,token }) => {
    const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;

                                           const [user, setUser] = useState(null);
                                           useEffect(() => {
                                           const fetchUserData = async () => {
                                           try {
                                           const response = await fetch(graphqlEndpoint, {
                                           method: 'GET',
                                           headers: {
                                           Authorization: `Bearer ${token}`, // Include your authentication token
                                          'Content-Type': 'application/json',
                                           },  });
                                           if (response.ok) {
                                           const userData = await response.json();
                                           setUser(userData);
                                           } else {
                                           console.error('Error fetching user data');
                                           } } catch (error) {
                                           console.error('Error fetching user data', error); 
                                         }};
                                         fetchUserData();
                                         }, []); // Run once on component mount
    

                                           
    
   
      //localStorage.removeItem('user_3')
      const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
   
    // Retrieve user email from localStorage
    const storedEmail = localStorage.getItem('user_email');

    // Update state with the retrieved email
    if (storedEmail) {
      setUserEmail(storedEmail);
    }
  }, []);

  const registerStoreValue = localStorage.getItem('register_store');

  // Check if register_store has a non-null value
  const isRegisterStorePresent = registerStoreValue !== "";
  
   //  console.log(localStorage)


        return (
                    <div className="listyouselfgettingStarted31">
                    <div className="listyouselfgettingStarted3Child" />
                    <div className="listyouselficonParent">
                    <div className="listyouselficon8">
                    <img className="listyouselficon9" alt="listyouself" src="gettingstarticon.svg" />
                    </div>
                    <div className="listyourselfgettingStarted">Getting Started</div>
                    </div>
              
                <div className="listyouselfletGetStarted1">
                    <div className="listyouselfframeParent">
                        <div className="listyouselfletsGetYouStartedUsernamParent">
                     
                   
              
                            <div className="listyouselfletsGetYou1">Let's get you started, Username</div>
                           
                            <div className="listyouselftakeAFew1">Take a few minutes and get the best out of ThirdRetail</div>
                        </div>
                        <div className="listyouselfframeGroup">
                            <div className="listyouselfretailSetupParent">
                                <div className="listyouselfretailSetup">Retail Setup</div>
                                <div className="listyouselfsteps">1 /4 steps</div>
                            </div>
                            <div className="listyouselfframeDiv">
                                <div className="listyouselfrectangleWrapper">
                                    <div className="listyouselfframeChild" />
                                </div>
                                <div className="listyouselfgroupParent">
                                    <div className="listyouselfframeWrapper">
                                        <div className="listyouselfgroupParent1">
                                            <img className="listyouselfframeItem" alt="listyouself" src="createacoountlistyourself.svg" />
                                            <div className="listyouselfgroupDiv">
                                                <div className="listyouselfframeWrapper1">
                                                    <div className="listyouselfcreateYourAccountParent">
                                                        <div className="listyouselfcreateYourAccount3">Create your account</div>
                                                        <div className="listyouselfrocket3" />
                                                    </div>
                                                </div>
                                                <div className="listyouselfcreateYourAccount4">Create your account</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="listyouselfbutton">
                                        <div className="listyouselficons6" />
                                        <img className="listyouselficons7" alt="" src="Check.svg" />
                                    </div>



                                </div>
                                <div className="listyouselfgroupParent2">
                                    <div className="listyouselfframeWrapper2">
                                        <div className="listyouselfgroupParent1">
                                            <img className="listyouselfframeItem" alt="listyouself" src="storerentyourself.svg" />
                                            <div className="listyouselfgroupDiv">
                                                <div className="listyouselfframeWrapper3">
                                                    <div className="listyouselfcreateYourAccountParent">
                                                        <div className="listyouselfaddYourStore1">Add your Store details
                                                       </div>
                                                        <div className="listyouselfrocket3" />
                                                    </div>
                                                </div>
                                                <div className="listyouselfcreateYourAccount4">Quickly add the relevant store details</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="listyouselfbutton2">
                                    <div className="listyouselficons6" />
                                    <div className="listyouselflabel1">  


                                    <div>
      {isRegisterStorePresent ? (
        // Render this if register_store has a non-null value
        <div className="listyouselfbutton">
          <div className="listyouselficons6" />
          <img className="listyouselficons7" alt="" src="Check.svg" />
        </div>
      ) : (
        // Render this if register_store is null
        <div className="listyouselfbutton10">
          <div className="listyouselflabel1">
          <Link  className ="link" to = "Form1"> Add Details</Link>
            
          </div>
        </div>
      )}
    </div>
                                       </div>
                                      </div>
                                </div>



{/*}
                                {bankInformation ? (
            <div className="istyouselfdashboard">Account Details</div>
            ) : (
              <div className="istyouselfdashboard">Getting Started</div>
                 )}    
{*/}


                                <div className="listyouselfgroupParent2">
                                    <div className="listyouselfframeWrapper4">
                                        <div className="listyouselfgroupParent6">
                                            <img className="listyouselfframeItem" alt="listyouself" src="storerentyourself.svg" />
                                            <div className="listyouselfgroupParent7">
                                                <div className="listyouselfframeWrapper5">
                                                    <div className="listyouselfaddShelvesInYourStoreWrapper">
                                                        <div className="listyouselfaddShelvesIn1">Add shelves in your store</div>
                                                    </div>
                                                </div>
                                                <div className="listyouselfaddTheDetails2">Add the details of the shelves you want to rent on the platform</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="listyouselfbutton2">
                                   
                                   <div className="listyouselficons6" />
                                   <div className="listyouselfbutton10">
                                   <div className="listyouselflabel1">  <Link  className ="link" to = "Form2"> Add Details</Link></div>
                                  </div>
                                 </div>
                                    <div className="listyouselfbuttonParent">
                                        <div className="listyouselfbutton4">
                                            <div className="listyouselfskip">Skip  </div>
                                        </div>
                                        <div className="listyouselfbutton5">
                                            <div className="listyouselfskip">Add Details</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="listyouselfgroupParent8">
                                    <div className="listyouselfframeWrapper">
                                        <div className="listyouselfgroupParent1">
                                            <img className="listyouselfframeItem" alt="listyouself" src="Banklistyourself.svg" />
                                            <div className="listyouselfgroupDiv">
                                                <div className="listyouselfframeWrapper7">
                                                    <div className="listyouselfaddBankDetailsWrapper">
                                                        <div className="listyouselfcreateYourAccount3">Add Bank details</div>
                                                    </div>
                                                </div>
                                                <div className="listyouselfaddTheDetails3">Currently disabled due to Bank Regulations.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="listyouselfbuttonbank">
                                        <div className="listyouselflabelbank">  Add Bank</div>

                                        {/*}  <Link  className ="link" to = "Form3"> </Link>  {*/}
                                    </div>
                                </div>
                              
                                          
                                    
                                 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="listyouselfsidebar">
                    <div className="listyouselfsidebarChild" />
                    <div className="listyouselfframeParent1">
                        <div className="listyouselflogoWrapper">
                            <div className="listyouselflogo1">

                            </div>
                        </div>
                        <div className="listyouselfselectFieldParent">
                        <div className="listyouselfselectField">
                        <div className="listyourselfinput">
  {localStorage.getItem('user_name1')}
</div>
                        </div>
                            <div className="listyouselfavatar">
                                <img className="listyouselfprofilePictureSmilingMillenIcon1" alt="listyouself" src="user-account.svg" />
                            </div>
                        </div>
                        <div className="listyouselfframeParent2">
                            <div className="listyouselficonGroup">
                                <div className="listyouselficon8">
                                    <img className="listyouselficon13" alt="listyouself" src="gettingstarticon.svg" />
                                </div>


                                
        <div className="istyouselfdashboard">Getting Started</div>
        
                            </div>
                            <div className="listyouselficonParent1">
                                <div className="listyouselficon8">
                                    <img className="listyouselficon13" alt="listyouself" src="lock.png" />
                                </div>
                                <div className="listyouselfdashboard">Dashboard</div>

                            </div>
                            <div className="listyouselficonParent2">
                                <div className="listyouselficon8">
                                    <img className="listyouselficon13" alt="listyouself" src="lock.png" />
                                </div>
                                <div className="listyouselfdashboard">Store Information</div>
                            </div>
                            
                        <div className="listyouselficonParent2" onClick={handleLogout} >
                            <div className="listyouselficon8">
                                <img className="listyouselficon13" alt="listyouself" src="logout.png" />
                            </div>
                            <div className="listyouselfdashboard"  >Logout     </div>
                            </div>
                        </div>



                           

                            </div>
          
                    <div className="listyouselfframeParent3">
                        <div className="listyouselficonParent3">
                            <div className="listyouselficon8">
                                <img className="listyouselficon13" alt="listyouself" src="lock.png" />
                            </div>
                            <div className="listyouselfchangeLogs">Change logs</div>
                        </div>
                        <div className="listyouselficonParent3">
                            <div className="listyouselficon8">
                                <img className="listyouselficon13" alt="listyouself" src="sharefeedbackicon.svg" />
                            </div>
                            <div className="listyouselfchangeLogs">Share feedback</div>
                        </div>
                       
                    </div>
                </div>
            </div>);


            }
     ;

export default Listyourshelf ;
