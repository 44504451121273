import "./MobileHero.css";

export const MobileHero = () => {
    return (
      <section className="rent-text-mobile-container">

                      <div className="mobile-beta-message">
                            <div className="rent-mobile-beta-message">
                                  <span className="rent-mobile-new-notifier">
                                        NEW
                                  </span>
                                  Marketplace Beta just launched
                            </div>
                      </div>

                      <div className="rent-mobile-text-heading">
                            
                            <div className="mobile-text-heading-first">
                                  Rent a 
                                  <div className="mobile-text-highlight-shelf">
                                        <div className="rent-mobile-shelf-text">
                                              shelf
                                        </div>
                                        <div className="rent-mobile-shelf-smile-image">
                                              <img src="./hero-section/shelf-smile.svg" alt="shelf"/>
                                        </div>

                                  </div>
                                  for
                            </div>

                            <div className="mobile-text-subheading">
                                  your business
                            </div>
                      </div>

                      <div className="rent-mobile-text-subheading">
                            Making distribution of your product easy and cost efficient!
                      </div>
                      <div className="rent-mobile-btn-search">
                            <div className="rent-mobile-synergy-btn">
                                  <a href="/synergy" className="rent-mobile-button-links rent-button-links">
                                        Find your Synergy
                                  </a>
                            </div>
                            <div className="rent-mobile-synergy-search">
                                  <img src="./hero-section/search.svg" alt="search"/>
                                  <input type="text" className="rent-mobile-synergy-search-bar" id="rent-mobile-synergy-search-bar" />
                            </div>
                      </div>
      </section>
  )
}