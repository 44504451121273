export const data = [
      {
            id: 1,
            title: "Ecosystem Wastage",
            description: "$300 billion worth of dead inventory gets accumulated led by oversupply, clogged shelves & unpredictable demand.",
            image: "Factory.svg"
      }, 
      {     id: 2,    
            title: "Underperforming Shelves",
            description: "Stuck products on shelves are roadblocks, preventing the introduction of exciting, money-making oerings.",
            image: "Gauge.svg"
      },
      {
            id: 3,
            title: "Declining Footfall",
            description: "Foot traffic is dropping, posing a big hurdle for retail growth. It's time to tackle this challenge head-on.",
            image: "Footprints.svg"
      }
]