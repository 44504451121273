import React from 'react'
import "./Help.css"
import { Link } from 'react-router-dom';

const Help = () => {
  return (
            <section className="help-container">
                  <div className="help-title">
                        How we help 
                        <span className="help-title-span">Brands</span>
                        ?
                  </div>

                  <div className="help-content text-highlight">
                        Are consistent orders pouring into your eCommerce backend from specific demographics and geographical areas? 
                        Streamline and boost these sales by securing a shelf with ThirdRetail.
                  </div>
                  
                  <div className="help-synergy-btn btn btn-dark">
                        <Link className="btn-dark synergy-btn" to="Synergy">Find your Synergy</Link>
                        <img className="help-icon-arrow" alt="arrow icon to redirect to synergy platform" src="arrow.svg" />
                  </div>
                  
            </section>
      );
}
 
export default Help ;
