import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react';

export class Mapp extends Component {
  
  state = {
    showingInfoWindow: true,
    activeMarker: true,
    selectedPlace: {},
    latitude: 0,
    longitude: 0,
    center: { lat: 0, lng: 0 }, // Initialize with a default center
  };

  componentDidUpdate(prevProps) {
    if (prevProps.locationDetails !== this.props.locationDetails) {
      const { latitude, longitude } = this.props.locationDetails;
      this.setState({
        latitude,
        longitude,
        center: { lat: latitude, lng: longitude }, // Update the center when latitude and longitude change
      });
    }
  }
 
  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: true,
        activeMarker: null,
      });
    }
  };



  render() {
    const mapStyles = {
      width: '1312px',
      height: '450px',
      overflow: 'hidden',
    };

    const containerStyle = {
      position: 'relative',
      width: '1100px',
      height: '100%',
    };

 
    const { latitude, longitude, center } = this.state;

    return (
      <Map
        containerStyle={containerStyle}
        google={this.props.google}
        zoom={15}
        style={mapStyles}
        center={center} // Use center instead of initialCenter
        onClick={this.onMapClicked}
      >
        <Marker
          onClick={this.onMarkerClick}
          position={{ lat: latitude, lng: longitude }}
          name={'Exact location provided after booking'}
        
        >
          <h3 style={{ backgroundColor: 'black', padding: '5px', borderRadius: '5px', margin: '0' }}>
            {this.state.selectedPlace.name}
          </h3>
        </Marker>

        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
        >
          <div>
            <h3>{this.state.selectedPlace.name}</h3>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyByI1MXrBW50io37CBScK-6Qk4b8WksZZc',
})(Mapp);


