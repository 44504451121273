import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';

const Synergydatatile2 = ({ onSelectedProductChange }) => {
  const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
  const [preferredProducts, setPreferredProducts] = useState([]);
  const [selectedOptionsDropdown, setSelectedOptionsDropdown] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    // Retrieve selected options from sessionStorage
    const selectedOptions = JSON.parse(sessionStorage.getItem('selectedOptions')) || [];

    const fetchPreferredProducts = async () => {
      try {
        const resultsArray = [];

        for (const option of selectedOptions) {
          const response = await axios.post(graphqlEndpoint, {
            query: `
              query {
                getStoreByRetailCategory(retailCategory: "${option}") {
                  preferredProduct
                }
              }
            `,
          });

          resultsArray.push(response.data.data.getStoreByRetailCategory.preferredProduct);
        }

        const allPreferredProducts = resultsArray.flat();
        setPreferredProducts(allPreferredProducts);
      } catch (error) {
        console.error('Error fetching preferred products:', error);
      }
    };

    if (selectedOptions.length > 0) {
      fetchPreferredProducts();
    }
  }, [graphqlEndpoint]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptionsDropdown(selectedOptions);
    // Track selected preferred products in another array
    setSelectedProducts(selectedOptions.map(option => option.value));
    // Pass selected products to the parent component or perform other actions
    onSelectedProductChange(selectedProducts);
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '325px',
      height: '48px'  , // Set your desired width for the entire component
      cursor: 'pointer',
    }),
    cursor:"pointer",
    menu: (provided) => ({
      ...provided,
      width: '325px', // Set your desired width for the dropdown menu
      borderRadius: 8, // Remove border-radius to eliminate the box around the menu
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Remove box-shadow
    }),
  };
  
  return (
    <div className='synergydatatile1'> 
    <div className="synergydataleft">
        <img className='Sidebar2' src = "../synergy/Sidebar2.svg" alt = " " />
       </div>
   


      <div className="synergydataright">
        <div className="arrayofline">
          <div className="line1synergyyes"> </div>
          <div className="line1synergyyes"> </div>
          <div className="line1synergy"></div>
          <div className="line1synergy"></div>
          <div className="line1synergy"></div>
        </div>
        <div className="synergyrightfind">FIND YOUR SYNERGY</div>
        <div className="synergytell">Tell us more about your product ?</div>

        <div className="synergydrop2" >
          <div className="synergy3headoption">Select product</div>
          <Select
          isMulti
          value={selectedOptionsDropdown}
        
          styles={customStyles}
       
          options={preferredProducts.map(product => ({ value: product, label: product }))}
          onChange={handleSelectChange}
         
        />
        </div>
      </div>
    </div>
  );
};

export default Synergydatatile2;
