import React from 'react'
import "./BrandImage.css"

export const BrandImage = () => {
    return (
        <div className="brand-image-container">
            <div className="image-background">
                <img className="brand-image" alt="" src="./brand-images/brand-image-bg.svg"/>

                <div className="float-container-left">
                    <div className="bag">
                        <img className="bag-icon" alt="" src="./brand-images/bag.svg"/>
                    </div>
                    <div className="timer">
                        <img className="timer-icon" alt="" src="./brand-images/timer.svg"/>
                        <div className="week">
                            <span className="week-count">2</span> Weeks
                        </div> 
                        <div className="shelf-life">
                            Shelf Life
                        </div>
                    </div>
                    <div className="success">
                        <img className="success-icon" alt="" src="./brand-images/success.svg"/>
                        <div className="money-success">
                            Money Transfer Successful
                        </div>
                    </div>
                </div>

                <div className="bag-open">
                    <img className="bag-open-icon" alt="" src="./brand-images/bag-open.svg"/>
                </div>

                <div className="chart-line">
                    <img className="chart-line-icon" alt="" src="./brand-images/chart-line.svg"/>
                </div>

                <div className="float-container-right">
                        <div className="shelfs-count">
                            <span className="shelfs-count-number">2</span> 
                            <span className="shelf-text">
                                Total Shelves Rented
                            </span>
                        </div>
                    <div className="income">
                        <div className="income-body">
                            <div className="income-value">
                                £245.00
                            </div> 
                            <div className="income-text">
                                Total Income
                            </div>
                        </div>
                        <img className="income-icon" alt="" src="./brand-images/chart.svg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}