import React from 'react'
import "./Brand.css"
import { BrandImage } from './BrandImage'
import { da } from 'date-fns/locale';

const Brand = () => {

    const gaps = [
        {
            id: 1,
            text: "Affordable Expansion",
        },
        {
            id: 2,
            text: "Local Experiential Reach",
        },
        {
            id: 3,
            text: "Responsive Marketplace",
        },
        {
            id: 4,
            text: "Online-Offline Synergy",
        },
        {
            id: 5,
            text: "Smart Shelf Selection",
        },
        {
            id: 6,
            text: "In-Store Revenue Optimization",
        },
    ];
  return (
   
            <section className="brand-container">
                <BrandImage />


                <div className="brand-gap-container">
                    <div className="brand-gap-centre">
                        <div className="brand-gap-highlight">
                                Bridging the gap between
                        </div>
                        <div className="brand-gap-title">
                            <p className="brands">Online & </p>
                            <p className="brands">Offline Retail </p>
                        </div>

                        <div className="brand-gap-subtitle">
                            {
                                gaps.map((gap) => (
                                    <div className="brand-gap" key={gap.id}>
                                        <img className="gap-image" src="./brand-images/subtract.svg" />
                                        <div className="gap-text">{gap.text}</div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </section>
        );
  };
    
   

export default Brand
