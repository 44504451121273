import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import DatePickParent from './Calender/dateRangeParent';
import { useState } from 'react';
import "./slphead.scss"

const Slphead = () => {


  const [authenticated, setAuthenticated] = useState(false);
  const [token, setToken] = useState('');
  const handleLogout = () => {
    localStorage.removeItem('storeId')
    localStorage.removeItem('authToken');
    setAuthenticated(false);
    setToken('');
    window.location.reload();
  };

  const location = useLocation();
  const isLoginPage = location.pathname === '/Login';

  // const first_name = localStorage.getItem('user_name1') || "Ash"
  // const last_name = localStorage.getItem('user_name2') || "Ketchum"
  // const email = localStorage.getItem('user_email') || "ash.ketchum@gmail.com"
  
  const first_name = localStorage.getItem('user_name1') 
  const last_name = localStorage.getItem('user_name2')
  const email = localStorage.getItem('user_email')
  
     if (!localStorage.getItem('authToken')) {
      // 'authToken' is not present, execute additional cleanup
      localStorage.removeItem('user_email');
      localStorage.removeItem('storeId')
      localStorage.removeItem('user_name1');
      localStorage.removeItem('user_name2');
      }


     
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
   
// for browseshop header

const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  
    return (
        <div className='slphead' >
            <div className="slpheadlogo">
            <Link className ="logo nav-item" to ="/">
                {
                  screenWidth > 495 ? <img className="" alt="" src="https://fixedimages.s3.amazonaws.com/Logo.jpg" width="142.84px"  height="28px"/> : <img className="logo-icon" alt="" src="./navbar/mobile-logo-light.svg" width="40px"/> 
                }
            </Link>
        </div>

        <div className="slpcalender ">
      <DatePickParent />
        </div>

        <div className="nav-item right">
                {first_name ? (
                  <div className="user-profile-menu" style={{height: "30px" , marginTop :"-10px" , marginLeft:"70px"}}>
                    <div className="logged-in-profile" onClick={toggleDropdown} >
                        <div className="logged-in-user-name" >
                          <div className="user-profile-first-name" >
                            {first_name} 
                          </div>
                        </div>
                          <img className='user-icon' src="https://fixedimages.s3.amazonaws.com/review2.png" alt=""  />
                      </div>

                        {dropdownVisible && (
                              <div className="user-menu-dropdown">
                                <div className="user-menu-items">

                                
                                <div className="user-menu-item">

                                      <img src = "/user.png" alt= "" height= "20px" width = "20px" style={{marginTop : "5px",marginLeft : "5px"}}/>
                                    <div className="logintext"> 
                                      {first_name} {last_name}
                                    </div>
                                </div>

                                <div className="user-menu-item">
                                    <div className="iconheadlinelogin"><img src = " https://fixedimages.s3.amazonaws.com/user.jpg" alt= "" /></div>
                                    <div className="logintext"> 
                                      {/* {localStorage.getItem('user_email')}  */}
                                      {email}
                                    </div>
                                </div>

                                <div className="user-menu-item">
                                      <img src = "/booking.png" alt= ""  height= "20px" width = "20px"/>
                                    <div className="logintext"> My Bookings </div>
                                      <img src = "/lock.png" alt = " "  height= "20px" width = "20px"/>
                                </div>

                                <div className="logout-user"  onClick={handleLogout}>
                                    <div className="user-menu-item">

                                            <img  src = "/logout.png" alt= "" height= "20px" width = "20px"/> 
                                        <div className="logintext" >Log out  </div>
                                    </div>
                                </div>

                                </div>
                              </div>
                        )}

                 </div>
                ) : (
                  isLoginPage ? null : (
                    <>
                    <Link className="nav-sub-item btn btn-light" style={window.innerWidth <= 768 ? { height: "15px" } : {height: "20px"  , marginRight : "-20px"}} to="/AuthPage1">Login</Link>
<Link className="nav-sub-item btn btn-dark" style={window.innerWidth <= 768 ? { height: "15px" } : {height: "20px",marginRight : "-20px" }} to="/Login">Sign Up</Link>

                    </>
                  )
                )}
            </div>
  
        </div>
    )
}
export  default Slphead ;





  
   