import React from 'react'
import "./aboutstatistics.scss"
const aboutstatistics = () => {
   return (
                <div className="statisticsstatsParent">
                      <div className="statisticsstats">
                            <div className="statisticsstatsChild" />
                            <img className="statisticsicon" alt="" src="44.png" />
                            <img className="statisticsstatsItem" alt="" src="Vector 353.svg" />
                            <div className="statisticsweSpeakWithContainer">
                                  <span className="statisticsweSpeakWithContainer1">
                              {/*}        <p className="statisticsourPowerfull">We speak with</p>{*/}
                                        <p className="statisticsourPowerfull">Our Waitlist</p>
                                       
                                  </span>
                            </div>
                            <div className="statisticsparent">
                                  <div className="statisticsdiv">10+</div>
                                  <div className="statisticsbrandsTrustUsWrapper">
                                        <div className="statisticsshelvesAvailable">Brands trust us</div>
                                  </div>
                            </div>
                            <div className="statisticsgroup">
                                  <div className="statisticsdiv1">40+</div>
                                  <div className="statisticsstoresListedWithUsWrapper">
                                        <div className="statisticsshelvesAvailable">Stores listed with us</div>
                                  </div>
                            </div>
                            <div className="statisticscontainer">
                                  <div className="statisticsdiv2">100%</div>
                                  <div className="statisticsshelvesAvailableWrapper">
                                        <div className="statisticsshelvesAvailable">Happy Customers </div>
                                  </div>
                            </div>
                      </div>
                </div>
    
  )
}

export default aboutstatistics
