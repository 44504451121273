import React from 'react'
import "./confirmation.scss"
import { useEffect } from 'react';
import { Link ,useParams} from 'react-router-dom'
import { useLocation } from 'react-router-dom';
const Confirmation = () => {
	const location = useLocation();
    const { storeId } = useParams();

    // Extracting parameters from the URL
    const storeName = new URLSearchParams(location.search).get('storeName');
    const imgUrl = new URLSearchParams(location.search).get('imgUrl');
	
	const itemCount = new URLSearchParams(location.search).get('itemCount');
    const selectState = new URLSearchParams(location.search).get('selectState');

    // Now, you can use storeName, imgUrl, and selectState in your component
   //  console.log('storeName:', storeName);
    //  console.log('imgUrl:', imgUrl);
	const addressMatch = selectState?.match(/"([^"]+)"\s*Latitude\s*:\s*([-+]?\d*\.\d+)\s*Longitude\s*:\s*([-+]?\d*\.\d+)/);

	// Check if matches were found
	const address = addressMatch ? addressMatch[1].trim() : null;
	const latitude = addressMatch ? parseFloat(addressMatch[2]) : null;
	const longitude = addressMatch ? parseFloat(addressMatch[3]) : null;
	 
    // console.log('selectState:', address);
	useEffect(() => {
		const disableBackButton = (event) => {
		  window.history.pushState(null, '', window.location.href);
		  window.onpopstate = function () {
			window.history.pushState(null, '', window.location.href);
		  };
		};
	
		disableBackButton();
	
		return () => {
		  window.onpopstate = null; // Clean up the event handler when component unmounts
		};
	  }, []);
	
  return (
    <div>
  
    		<div className="confirmationgroupParent">
      			<div className="confirmationframeWrapper">
        				<div className="confirmationframeDiv">
          					<div className="confirmationbookingConfirmedParent">
            						<div className="confirmationbookingConfirmed" style={ {width : "400px"}}>Booking Requested</div>
            						<img className="confirmationpositiveSolidIcon1" alt="" src="/Positive Solid.svg" />
          					</div>
          					<div className="confirmationitsOfficialGetReadyToDiParent">
            						<div className="confirmationitsOfficialGetContainer">
              							<span className="confirmationitsOfficialGetContainer1">
                								<p className="confirmationitsOfficial">It’s Official!</p>
                								<p className="confirmationitsOfficial">Booking send for approval</p>
              							</span>
            						</div>
            						<div className="confirmationyourReservationIs1">Our team will reach out soon</div>
          					</div>
        				</div>
      			</div>
      			<div className="confirmationbuttonParent">
        				<div className="confirmationbutton1">
          					<div className="confirmationlabel"><Link style={{ textDecoration: 'none' }}to = "/"> <div className="confirmhome">Go to Home page</div></Link></div>
        				</div>
        				<div className="confirmationbutton2">
          					<div className="confirmationlabel">My Bookings</div>
        				</div>
        				<div className="confirmationframeParent">
          					<div className="confirmationchipParent">
            						<div className="confirmationchip">
              							<div className="confirmationlabelWrapper">
                								<div className="confirmationlabel2">
                  									<span className="confirmationbookingId">Booking ID:</span>
                  									<span>TRR790</span>
                								</div>
              							</div>
            						</div>
            						<div className="confirmationdetails">
              							<div className="confirmationline1" />
              							<div className="confirmationbordeauxGetawayParent">
                								<div className="confirmationbordeauxGetaway">{storeName}</div>
                								<div className="confirmationlocation">
                  									<img className="confirmationmappinIcon" alt="" src="/MapPin.png" />
                  									<div className="confirmationbroadwayNewYorkWrapper">
                    										<div className="confirmationbroadwayNewYork1">{address}</div>
                  									</div>
                								</div>
              							</div>
              							<div className="confirmationbutton3" />
            						</div>
            						<div className="confirmationextra">
              							<div className="confirmationfeb22221">Feb 22, 24 - Mar 05, 24 • {itemCount} Shelves booked</div>
            						</div>
          					</div>
          					<div className="confirmationimage1">
            						<div className="confirmationimageChild" />
            						<img className="confirmationimageIcon" alt=""  src= {imgUrl}/>
          					</div>
        				</div>
      			</div>
    		</div>

    </div>
  )
    }

export default Confirmation
