import React, { useState } from 'react';
import { useEffect } from 'react';

const Synergydatatile1 = ({ onSelectedOptionsChange }) => {
  const options = ['FMCG', 'Fashion', 'Electronics', 'Kids', 'DIY & Hardware', 'Furniture & Interiors', 'Gardening'];
  const [selectedOptions, setSelectedOptions] = useState([]);
  const images = ['/fmcg-icon.svg', '/fashion-icon.svg', '/electronics-icon.svg', '/kids-icon.svg', '/diy-icon.svg', '/furniture-icon.svg', '/gardening-icon.svg'];
  useEffect(() => {
    sessionStorage.setItem('selectedOptions', JSON.stringify(selectedOptions));
  }, [selectedOptions]);

 
      // Pass the selected options to the parent component
      onSelectedOptionsChange(selectedOptions);
      
  
    // Log selected options dynamically
   //  console.log('Selected Options:', selectedOptions);
    const handleButtonClick = (option) => {
      setSelectedOptions((prevOptions) => {
        if (prevOptions.includes(option)) {
          return prevOptions.filter((selected) => selected !== option);
        } else {
          return [...prevOptions, option];
        }
      });
    };
  


  return (
    <div className='synergydatatile1'>

       <div className="synergydataleft">
        <img className='Sidebar1' src = "../synergy/Sidebar1.svg" alt = " " />
       </div>
    <div className="synergyrightside">
        <div className="synergydataright">
        <div className="arrayofline">
          <div className="line1synergyyes"> </div>
          <div className="line1synergy"></div>
          <div className="line1synergy"></div>
          <div className="line1synergy"></div>
          <div className="line1synergy"></div>
        </div>
        <div className="synergyrightfind">FIND YOUR SYNERGY</div>
        <div className="synergytell">Tell us what kind of products do you want to shelf out?</div>
      
        <div className="optionsynergytile1">
       
          {options.map((option, index) => (
            <div
              key={index}
              id = "synergyicontile"
              className={`synergyicontile ${selectedOptions.includes(option) ? 'selected' : ''}`}
              onClick={() => handleButtonClick(option)}
           
   
            >
             
              <img src={images[index]} alt={`${option} Icon`} style ={{marginRight : "10px "}} />
              <span>{option}</span>
            </div>
          ))}
        </div>  
        </div>
      </div>
    </div>
  );
};

export default Synergydatatile1;
