import React from 'react'
import "./aboutoptimise.scss"
import { Link } from 'react-router-dom';
const aboutoptimise = () => {
          return (
                <div className="optimisesection">
                      <div className="optimisesectionChild"/>
                      <div className="optimisebackground2"/>
                      <div className="optimisebackground3"/>
                      
                      <div className="optimisesectionItem"/>
                      <div className="optimisebuttonParent">
                            <div className="optimisebutton">
                              
                            <Link className="optimiselabel" to="/AuthPage">List Now</Link>
                            </div>
                            <div className="optimiseframeWrapper">
                                  <div className="optimiselistWithUsParent">
                                        <div className="optimiselistWithUs">List with US</div>
                                        <div className="optimisetrackAnyHashtags1">Optimise your in-store revenue</div>
                                  </div>
                            </div>
                      </div>
                      <div className="optimisegroupParent">
                            <div className="optimisegroupWrapper">
                                  <div className="optimisegroupDiv">
                                        <div className="optimiserectangleParent">
                                              <div className="optimiseframeChild"/>
                                           
                                              <div className="optimisestore">
                                                    <div className="optimisestoreInformation">Store Information</div>
                                                    <div className="optimisestoreChild"/>
                                              </div>
                                              <div className="optimiserectangleGroup">
                                                    <div className="optimisegroupChild"/>
                                                  
                                              </div>
                                              <div className="optimiseframeParent">
                                                    <div className="optimiserectangleContainer">
                                                          <div className="optimiseframeItem"/>
                                                          <div className="optimiseframeItem"/>
                                                    </div>
                                                    <div className="optimisegroupInner"/>
                                              </div>
                                              <div className="optimiserectangleParent1">
                                                    <div className="optimisegroupChild"/>
                                                    <div className="optimisegroupInner"/>
                                              </div>
                                        </div>
                                  </div>
                            </div>
                            <div className="optimiserectangleParent2">
                                  <div className="optimisegroupChild2" />
                                  <div className="optimisestoreLocationParent">
                                        <div className="optimisestoreLocation">Store Location</div>
                                        <img className="optimisemappinIcon" alt="" src="MapPin.png" />
                                  </div>
                                  <div className="optimiseframeWrapper1">
                                        <div className="optimisemapWrapper">
                                              <img className="optimisemapIcon" alt="" src="Mapoptimise.png" />
                                        </div>
                                  </div>
                            </div>
                      </div>
                </div>);
}
    

export default aboutoptimise
