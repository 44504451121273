import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import StoreBlock from './StoreBlock';
import Select from 'react-select';
import Mapp from './Map/Mapp';
import "./Browsestore.scss";

const Browsestore = () => {
  const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
  const shelf_type = ['Rack', 'Standing'];
  const Types = ['Chain Store', 'Small Store', 'Brand Store', 'Supermarket', 'Multi Brand', 'Boutique Store', 'Others'];
  const Demographicss = ['Baby products', 'Minor', '18-24', '25-35', '45-65', '65+'];
  const Categorys = ['FMCG', 'Fashion', 'Electronics', 'Furniture & Interior', 'Kids', 'DIY & Harware', 'Gardening'];

  const [selectedshelf_type, setSelectedshelf_type] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedDemographic, setSelectedDemographic] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  const handleshelf_typeChange = (event) => {
    setSelectedshelf_type(event.target.value);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleDemographicChange = (event) => {
    setSelectedDemographic(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const location = useLocation();

  const responseData = location.state?.responseData;

  const searchParams = new URLSearchParams(location.search);
  const formattedStartDate = searchParams.get("start");
  const formattedEndDate = searchParams.get("end");

  useEffect(() => {
   // console.log("Formatted Start Date in Browser Page:", formattedStartDate);
   // console.log("Formatted End Date in Browser Page:", formattedEndDate);
  }, [formattedStartDate, formattedEndDate]);

  const startDate = localStorage.getItem("selectedStartDate");
  const endDate = localStorage.getItem("selectedEndDate");

 // console.log("start-date from local storage", startDate)
 // console.log("end-date from local storage", endDate)

  const [storeData, setStoreData] = useState([]);

  useEffect(() => {
    let graphqlQuery;
  
    if (startDate && endDate) {
      // If both startDate and endDate are present
      graphqlQuery = `
        query {
          getRegisterStoresByAvailableDate(startDate: "${startDate}", endDate: "${endDate}") {
            storeId,
            StoreName,
            SelectedState,
            AverageOrderValue,
            stroreImageUrl,
            storeArea
            ShopCategory,
            
            CustomerDemography
            shelfDetails {
              shelfName,
              shelfRent,
              createdDate,
              shelf_id,
              shelfImage_url
            }
          }
        }
      `;
    } else {
      // If either startDate or endDate is null
      graphqlQuery = `
        query {
          getSynergyMarketList(averageOrderValue: "",
            location: "",
            customerDemographics: [""],
            prefferedProduct: [""]) {
            storeId,
            StoreName,
            storeArea
            SelectedState,
            AverageOrderValue,
            stroreImageUrl,
            ShopCategory,
            CustomerDemography
            shelfDetails {
              shelfName,
              shelfRent,
              createdDate,
              shelf_id,
              shelfImage_url
            }
          }
        }
      `;
    }
  
    axios.post(graphqlEndpoint, { query: graphqlQuery })
      .then(response => {
        if (startDate && endDate) {
          setStoreData(response.data.data.getRegisterStoresByAvailableDate || []);
        } else {
          setStoreData(response.data.data.getSynergyMarketList || []);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [graphqlEndpoint, startDate, endDate]);
  
  const extractLatLong = (selectedState) => {
    const match = selectedState.match(/Latitude : ([^ ]+) Longitude : ([^ ]+)/);
    if (match) {
      const latitude = parseFloat(match[1]);
      const longitude = parseFloat(match[2]);
      return { latitude, longitude };
    }
    return null;
  };

  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = storeData.slice(indexOfFirstItem, indexOfLastItem);

  const latLongArray = currentItems.map((item) => {
    const { SelectedState } = item;
    return extractLatLong(SelectedState);
  });

  const addressesOnCurrentPage = currentItems.map((item) => item.SelectedState);
  const storeNameOnCurrentPage = currentItems.map((item) => item.StoreName);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const nextPage = () => setCurrentPage((prev) => prev + 1);
  const prevPage = () => setCurrentPage((prev) => prev - 1);

  return (
    <div className='supermarketoptions'>
      <div className="supermarketoptionsdata">
        <div className="superMarketsIn">Stores in</div>
        <div className="london"> London </div>
        <div className="results" >Results  {startDate} {endDate}</div>
        <div className="options">
          <div className="option">
            <select id="option" value={selectedshelf_type} onChange={handleshelf_typeChange}>
              <option value="">Shelf Type</option>
              {shelf_type.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="option">
            <select id="option" value={selectedType} onChange={handleTypeChange}>
              <option value="">Store Type</option>
              {Types.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="option">
            <select id="option" value={selectedDemographic} onChange={handleDemographicChange}>
              <option value=""> Demography </option>
              {Demographicss.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="option">
            <select id="option" value={selectedCategory} onChange={handleCategoryChange}>
              <option value="">Shop Category</option>
              {Categorys.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className='layerslp'>
          {currentItems.map((data, index) => (
            <StoreBlock key={index} {...data} />
          ))}
          <div className="pagination">
            <button className="prevButton" onClick={prevPage} disabled={currentPage === 1}>
              Prev
            </button>
            {Array.from({ length: Math.ceil(storeData.length / itemsPerPage) }).map((_, index) => (
              <span
                key={index}
                onClick={() => paginate(index + 1)}
                className={currentPage === index + 1 ? 'active' : ''}
              >
                {index + 1}
              </span>
            ))}
            <button className="nextButton" onClick={nextPage} disabled={currentPage === Math.ceil(storeData.length / itemsPerPage)}>
              Next
            </button>
          </div>
        </div>
      </div>

      <div className="smpgraph">
        <Mapp latLongArray={latLongArray} storeName={storeNameOnCurrentPage} />
      </div>
    </div>
  )
};

export default Browsestore;
