import React from 'react'
import { useState } from 'react';

const Synergydatatile3 = () => {
  const optionsage = ['Infant Products', '4-15YO', '16-24YO', '25-35YO', '35-45YO', '45-55YO', '55-65YO'  ,'65YO+'];

  const [selectedOptionsage, setSelectedOptionsage] = useState([]);
  const handleCheckboxChangeage = (optionage) => {
    if (selectedOptionsage.includes(optionage)) {
      setSelectedOptionsage(selectedOptionsage.filter((selected) => selected !== optionage));
    } else {
      setSelectedOptionsage([...selectedOptionsage, optionage]);
    }}
    //  console.log(selectedOptionsage)
  return (
    <div className='synergydatatile1'>
       <div className="synergydataleft">
        <img className='Sidebar3' src = "../synergy/Sidebar3.svg" alt = " " />
       </div>
     
      <div className="synergydataright">
      <div className="arrayofline">
          <div className="line1synergyyes"> </div>
          <div className="line1synergyyes"> </div>
          <div className="line1synergyyes"> </div>
          <div className="line1synergy"></div>
          <div className="line1synergy"></div>


        </div>
        <div className="synergyrightfind">FIND YOUR SYNERGY</div>
        <div className="synergytell">What is the ideal age of your Target Audience ?</div>
     
      <div className="optionsynergy">
     
    
     {optionsage.map((optionage, index) => (
        <label
          key={index}
          className={`checkbox13-container ${selectedOptionsage.includes(optionage) ? 'selected' : ''}`}
        >
          <input
          id = " synergy3"
            type="checkbox"
            checked={selectedOptionsage.includes(optionage)}
            onChange={() => handleCheckboxChangeage(optionage)}
          />
          {optionage}
        </label>
      ))}
    
    </div>

     </div>
    </div>
  )
}

export default Synergydatatile3
