import React from 'react'
// import "./Faqs.scss"
import "./Faqs.css"
import Accordino from './Accordino/Accordino';         


const Faqs = () => {
  const receiverEmail = 'info@thirdretail.com'; 
  const handleEmailButtonClick = () => {
    const emailAddress = 'info@thirdretail.com'; 
    const subject = 'Enquiry';
    const body = 'Hello, I have an enquiry:';

    const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${emailAddress}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Open the browser-based email client (in this case, Gmail)
    window.open(mailtoLink, '_blank');
  }

  return (
        <section className="faq-container">
            <div className="faq-title">
              <p className="frequently faq-title-text">Frequently</p>
              <p className="sub-title">
                <span className="faq-sub-title-text">Asked</span>
                <span className="questions faq-sub-title-text"> Questions </span>
              </p>
            </div>

            <div className="faq-section">
                <div className="faq-questions">
                    <div className="faqs-frame-parent">
                      <Accordino/>
                    </div>
                </div>

                <div className="faq-enquiry">
                  <div className="faq-card">
                        <img className="faq-card-img" alt="chat icon" src="ChatCircleDots.svg" />
                        <div className="faq-card-title">Do you have more questions?</div>
                        <div className="faq-card-text">End-to-End Solution for e-Commerce and Stores.</div>
                        <div onClick={handleEmailButtonClick} className="btn btn-dark" id="faq-card-btn">
                          <img className="faqsicon2" alt="email envelope image" src="envelop.svg"/>
                          <div className="faq-card-btn-text">
                            Shoot us an enquiry
                          </div> 
                        </div>
                  </div>
                </div>

            </div>

        </section>
);
}                                                                                                                                                                                                                                                                       
export default Faqs
