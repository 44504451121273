import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';


  const ShelfComponent = ({ rackNumber, shelfName, shelfImage, shelfDescription, shelfRent, onSelect  ,shelfArea}) => {
    const [isChecked, setIsChecked] = useState(false);
  
    const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
      onSelect({ rackNumber, shelfName, shelfRent, isChecked: !isChecked });
    };
  // console.log(shelfImage)
  
  return (
    <div className="sdpselfrack">
      <div className="sdpselfrackChild" />
      <img className="sdpselfrackChild1" alt="" src={shelfImage} />
      <div className="sdpselfframeParent2">
        <div className="sdpselfrack1Parent">
          <div className="sdpselfrack1">{`Rack #${rackNumber}`}</div>
          <div className="sdpselfthisIsA3">{shelfDescription}</div>
        </div>
        <div className="sdpselfframeWrapper">
          <div className="sdpselfparent">
            <div className="sdpselfdiv">{`£${shelfRent}`}</div>
            <div className="sdpselfshelf">/shelf</div>
          </div>  
          <div className='area_shelf'>•{shelfArea}sq. feet</div>
        </div>
       
      </div>
      <div className="sdpselfcheckbox">
        <div className="sdpselfcheckbox1">
          <div className="sdpselfcheckboxChild" >
          <input type="checkbox" checked={isChecked}  onChange={handleCheckboxChange}  style={{ width: '20px', height: '20px', borderRadius: '5px', marginRight: '5px' }}/>
          </div>
         <div className='slectedinputshlef' style={{color : "#1A212B"}}> Select Shelf</div>


        </div>
      </div>
     
      
    </div>
  );
};

ShelfComponent.propTypes = {
  rackNumber: PropTypes.number.isRequired,
  shelfImage: PropTypes.string.isRequired,
  shelfDescription: PropTypes.string.isRequired,
  shelfRent: PropTypes.number.isRequired,
 isSelected: PropTypes.bool,
};

export default ShelfComponent;


