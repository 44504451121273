// StoreBlock.jsx
import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const StoreBlock = ({  storeId , stroreImageUrl, ShopCategory, CustomerDemography , StoreName, SelectedState, shelfDetails, storeArea }) => {

  const imageUrls = stroreImageUrl ? stroreImageUrl.split(',') : [];
  
  // Use only the first image URL
  const mainImageUrl = imageUrls.length > 0 ? imageUrls[0] : null;

  const selectedState = SelectedState;

// Extracting address
const addressMatch = selectedState?.match(/"([^"]+)\"Latitude/);
const address = addressMatch ? addressMatch[1].trim() : null;

// Extracting latitude and longitude
const latitudeMatch = selectedState?.match(/Latitude : ([-+]?\d*\.\d+)/);
const longitudeMatch = selectedState?.match(/Longitude : ([-+]?\d*\.\d+)/);

// Check if matches were found
const latitude = latitudeMatch ? parseFloat(latitudeMatch[1]) : null;
const longitude = longitudeMatch ? parseFloat(longitudeMatch[1]) : null;

// State to hold location details
const [locationDetails, setLocationDetails] = useState({
  address: address || '', // Remove leading spaces
  latitude: latitude,
  longitude: longitude,
});


  // Update locationDetails on mount
  useEffect(() => {
	// Check if both latitude and longitude are not null
	if (latitude !== null && longitude !== null) {
	  setLocationDetails({
		address,
		latitude,
		longitude,
	  });
	}
  }, [address, latitude, longitude]);

// console.log("at sdpmap ", locationDetails);

 // console.log(stroreImageUrl)
  // Define this function outside your component
const getMinimumShelfRent = (shelfDetails) => {
  const minShelfRent = Math.min(...shelfDetails.map(shelf => shelf.shelfRent));
  return minShelfRent;
 
};
// console.log(getMinimumShelfRent) 
const DemographyBox = ({ value }) => (
  <div className="commonfriend">
    <div className="commonfrienddata">{value}</div>
  </div>
);

  return (
    <Link  className  = "sdplik"  style={{ textDecoration: "none" }} to={`/Browseshops/Sdp/${storeId}`}>
     
        <div className="descriptions">
        <div className="imagestore1">
        {mainImageUrl && <img  className = "imagestore1"src={mainImageUrl} alt="Main Store Image" />}
        </div>
        <div className="browsestoredata">
          <div className="dataparent">
           
          {CustomerDemography.length > 1 &&
          CustomerDemography.map((value, index) => (
            <DemographyBox key={index} value={value} />
          ))}
           
    
   
            <div className="bestseller">
              <div className="bestsellerdata">{ShopCategory}</div>
            </div>

          </div>

          <div className="shopname">{StoreName}</div>
          <div className="location">
            <div className="mappin">
              <img alt="" src="mappin.svg" />
            </div>
            <div className="addresss">{address}</div>
          </div>
          <div className="pricerating">
      <div className="rating1">
        <span className='rating0'> 0.0 
       
        <span className="rating2">
         <img src = "./review.svg" alt = " " />
         <img src = "./review.svg" alt = " " />
         <img src = "./review.svg" alt = " " />
         <img src = "./review.svg" alt = " " />
         <img src = "./review.svg" alt = " " />
        
      </span>
      </span>
      <div className="rating3">
       (Not yet Reviewed )
        
      </div>
      </div>
      <div className="pricerating">
            <div className="price">
              <div className="startsfrom">Starts from</div>
              <div className="rate">
      {shelfDetails.length > 0 && (
        <div className='rateflex'>
          <div className="weightrate"> £{getMinimumShelfRent(shelfDetails)}</div>
          <div className="ratelight">/day</div>
        </div>
      )}
    </div>
            </div>
            </div>
           
          </div>
          <div className='line'></div>
          <div className="store_area" >{storeArea}Sq.ft</div>
        
        </div>
      
      </div>
    </Link>
  );
};

export default StoreBlock;
