import React from 'react'
import "./sdpself.scss"
import { Link } from 'react-router-dom';
import { useState  ,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import ShelfComponent from './ShelfComponent'; 
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Calender2 from '../calender/Calender2';

const SdpSelf = ({shelfDetails , storeId }) => {
   //  console.log("storeid at sdp" , storeId )
	  localStorage.setItem('storeId',storeId)
  //  console.log(localStorage)

//	console.log("at sdpshelf", shelfDetails)
	shelfDetails.forEach((shelf, index) => {
	//	console.log(`Shelf #${index + 1} - ID: ${shelf.shelf_id}, Rent: £${shelf.shelfRent}`);
	  });
	
    const shelfAreaArray = shelfDetails.map(shelf => shelf.shelfArea);

	//  console.log("at sdpshelf", shelfDetails);
	  const [selectedShelves, setSelectedShelves] = useState([]);

	  const handleShelfSelect = (selectedShelf) => {
		const updatedSelection = [...selectedShelves];
		const existingIndex = updatedSelection.findIndex((shelf) => shelf.rackNumber === selectedShelf.rackNumber);
	
		if (existingIndex !== -1) {
		  updatedSelection.splice(existingIndex, 1);
		} else {
		  updatedSelection.push(selectedShelf);
		}
	
		setSelectedShelves(updatedSelection);
	  };
	
	//  console.log("Selected Shelves: ", selectedShelves);

   

    const no_of_days = localStorage.getItem('daysCount'); // Remove the space after the apostrophe

   
    const daysCount1 = no_of_days !== null ? parseInt(no_of_days, 10) : 1;

   // console.log("days count:", daysCount1);

	 const totalRent1 = selectedShelves.reduce((acc, selectedShelf) => {
		// Convert shelfRent to a number using parseFloat or parseInt
		const shelfRentAsNumber = parseFloat(selectedShelf.shelfRent);
		return acc + shelfRentAsNumber;
	  }, 0); 

	  const Bookingchargerpershelf = selectedShelves.length;
     const totalRent2  = totalRent1*daysCount1 + 20*(Bookingchargerpershelf) ;
	 const totalRent =  (totalRent2) + 0.20*(totalRent2)  ;

   const navigate = useNavigate();
   const [isAuthenticated, setIsAuthenticated] = useState(false);

   useEffect(() => {
     const authToken =  sessionStorage.getItem("ecomUser_id");
   
     setIsAuthenticated(!!authToken);
  
   }, [setIsAuthenticated, navigate]);
 
   const selectedStartDate = localStorage.getItem('selectedStartDate');
   const selectedEndDate = localStorage.getItem('selectedEndDate');
   const [isCalendarOpen, setCalendarOpen] = useState(false);

   const handleButtonClick = () => {
     setCalendarOpen(!isCalendarOpen);
   };


    const handleClick = () => {
      toast.error('Please select the shelf and date', {
        position: toast.POSITION.TOP_CENTER
      });
    };

    // generate  boking id 

    const generateRandomAlphaNumeric = () => {
      const alphanumeric = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let result = '';
      for (let i = 0; i < 4; i++) {
        result += alphanumeric.charAt(Math.floor(Math.random() * alphanumeric.length));
      }
      return result;
    };

    const getbooking_id = () => {
      const booking_id = generateRandomAlphaNumeric();
      const bookingIdWithPrefix = 'TR2024' + booking_id;
      sessionStorage.setItem('booking_id', bookingIdWithPrefix);
      // Optionally, you can perform any other actions here when the div is clicked
    };
     return (    
     <div className="sdpmaincompent">
   <div className="sdpbigparent">
                
    		<div className="sdppart1">
				<div className="totalshelfavailableandcalender">
               <div className="totalAvailableShelves">Total available shelves</div>
			   <div className="sdpplaceholder">	
         <div className="sdpselectField" >
         <div className="sdpinput">

<div className='sdpplaceholder'>
      {selectedStartDate ? (
        <div className="sdpplaceholder">{selectedStartDate}  -  {selectedEndDate}</div>
      ) : (
        <div>Please select dates</div>
      )}
     
    </div>

</div>
<div className="sdpicon">
<div>
 
<img className="sdpicon1" alt=""  onClick={handleButtonClick} src="/calendersdp.svg"  style={{marginTop :  "10px"}}/>
<div className="onclickopencalender">
      {isCalendarOpen && <Calender2 />} 
      </div>
    </div>

</div>
</div>
</div>
 </div>

 <div className="sdptags">
 <div className="sdptagboxall">All</div>
	<div className="sdptagbox">FMCG</div>
 </div>
	                
						<div className="sdpbigparent2">
                       <div className="sdpselfframeParent">
                       
                       <div className="sdpselfrackParent">
                     {shelfDetails.map((shelf, index) => (
                   <ShelfComponent
                   key={index}
              rackNumber={index + 1}
              shelfImage={shelf.shelfImage_url}
			  shelfName={shelf.shelfName}
              shelfDescription={`This is a rack number ${index + 1} in the store`}
              shelfRent={shelf.shelfRent}
              shelfArea = {shelf.shelfArea}
			  onSelect={handleShelfSelect}
             
                />
               ))}
                </div>
              </div>


						</div>
						</div>
	


	


						<div className="sdpbreakbb">
  <div className="sdpbreakpriceDetailsWrapper">
    <div className="sdpbreakpriceDetails">

		<div className="datepickedforrent">
			<div className="startdate">
			<div className="datepicked1">Start date</div>
			<div className="datepicked2">{localStorage.getItem('selectedStartDate')}</div>
			</div>
			<div className="datepicked"><img  className = "rightcalenderarrow " src = "/arrowright.svg" alt = "" /></div>

			<div className="enddate">
			<div className="datepicked1">End date</div>
			<div className="datepicked2" >{localStorage.getItem('selectedEndDate')}</div>
			</div>
		</div>
    

		<div className="totalshlefpickeddata">
		<div className="totalShelvesPicked">Total shelves picked </div>
		<div className="shelves">{`${selectedShelves.length} shelf`}</div>
		</div>
      <div className="sdpbreakrow2">
        <div className="sdpbreakbookingBreakup">Booking Breakup</div>
        <div className="rentdetailspershelf">
	  {selectedShelves.map((selectedShelf, index) => (
        <div key={index} className="sdpbreakrow3">
          <div className="sdpbreaktotalShelves">{`Shelf #${selectedShelf.rackNumber} Rent`}</div>
          <div className="sdpbreakdiv1">{`£${selectedShelf.shelfRent}`}</div>
        </div>
      ))}
	  </div>
      

      <div className="sdpbreakrow3">
        <div className="sdpbreaktotalShelves">Booking Fee</div>
        <div className="sdpbreakdiv1">£20/shelf</div>
      </div>

	  <div className="sdpbreakrow3">
        <div className="sdpbreaktotalShelves">VAT</div>
        <div className="sdpbreakdiv1">20%</div>
      </div>

        <div className="sdpbreakrow3">
        <div className="sdpbreaktotalShelves">No. of Days </div>
        <div className="sdpbreakdiv1">{no_of_days || 1}</div>
        </div>
        <div className="sdpbreakrow8">
        <div className="sdpbreakbookingBreakup">Total</div>
        <div className="sdpbreakdiv6">{`£${totalRent}`}</div>
       </div>
	 
    </div>
  </div>

 
    <div className="sdpbreakbutton">
      <div className="gtcheckout1" style={{zIndex : -5 ,cursor: 'pointer'} }  onClick={handleClick}> Rent Now</div>
     
    {totalRent !== 0  && no_of_days > 1 &&(
      <div className="sdpbreaklabel1">
      
     
      <div>
    
    
      {isAuthenticated ? (
           <div>
           <Link 
           style={{ textDecoration: 'none' }}
           to={{
           pathname: `/checkout/${encodeURIComponent(storeId)}`,
           search: `?selectedShelves=${encodeURIComponent(
           JSON.stringify(selectedShelves)
           )}&totalRent1=${encodeURIComponent(totalRent1)}&totalRent=${encodeURIComponent(
           totalRent
           )}`,
           }}
           onClick={() => {
        //console.log("Values to be passed:", {  totalRent1, selectedShelves,  totalRent,  });
           }}>
           <div className="gtcheckout" style={{ cursor: 'pointer' }}  onClick= {getbooking_id}>Rent Now</div>
           </Link>
           </div> 
           ) : (
            <Link to="/AuthPage2" style={{ textDecoration: 'none' }}>
                <div className="gtcheckout" style={{ cursor: 'pointer' , }} onClick= {getbooking_id}>Rent Now</div>
            </Link>
        )}
        </div>

	 

 </div>
   ) } 
    </div>
 




</div>
</div>
    		</div>
			</div>
             
          


            
            );
}



export default SdpSelf
