import React from 'react'
import "./aboutteam.scss"
const aboutteam = () => {
  return (
 
                  <div className="teamgroupParent">
                  <div className="teamframeWrapper">
                  <div className="teammeetOurParent">
                  <div className="teammeetOur">Meet Our</div>
                  <div className="teamamazingTeam">
                  <span className="teamamazingTeamTxtContainer">
                  <span>Amazing</span>
                  <span className="teamteam"> Team</span>
                  </span>
                  </div>
                  <div className="teamdiscoverTheBrilliance1">Discover the brilliance behind  Embrace. Our team blends innovation and artistry to craft digital wonders that captivate</div>
                  </div>
                  </div>
                  <div className="teammember4Parent">
                  <div className="teammember1">
                                        <img className="teamuserpicIcon" alt=""  src="/review5.jpg" />
                                        <div className="teammemberdetails">
                                        <div className="teammembername">Athar</div>
                                        <div className="teamrole">Co-Founder & CEO</div>
                                        <div className="socialinfoteam">
                                        <div className="teamfaX" > 
                                        <img alt = "" src = "twitter.png" height="20px" width="20px"/>
                                        </div> 
                                        <div className="teamfalinkdin">
                                        <a href="https://www.linkedin.com/in/connectwithathar/" target="_blank" rel="noopener noreferrer">
                                        <img alt ="" src ="linkedin.png" height="20px" width="20px" />
                                        </a>
                                        </div>
                                        <div className="teamfaemail">
                                        <img alt= "" src ="mail.png"  height="20px" width="20px"/>
                                        </div>
                                        </div>
                                        </div>
                        </div> <div className="teammember1">
                                        <img className="teamuserpicIcon" alt=""  src="/review9.png" />
                                        <div className="teammemberdetails">
                                        <div className="teammembername">Anil </div>
                                        <div className="teamrole">Co-Founder & COO </div>
                                        <div className="socialinfoteam">
                                        <div className="teamfaX" > 
                                        <img alt = "" src = "twitter.png" height="20px" width="20px"/>
                                        </div> 
                                        <div className="teamfalinkdin">
                                        <a href="https://www.linkedin.com/in/aniljunagal" target="_blank" rel="noopener noreferrer">
                                        <img alt="" src="linkedin.png" height="20px" width="20px" />
                                        </a>
                                        </div>
                                        <div className="teamfaemail">
                                        <img alt= "" src ="mail.png"  height="20px" width="20px"/>
                                        </div>
                                        </div>
                                        </div>
                        </div> <div className="teammember1">
                                        <img className="teamuserpicIcon" alt=""  src="/review2.png" />
                                        <div className="teammemberdetails">
                                        <div className="teammembername">Jatin</div>
                                        <div className="teamrole"> Lead Engineer</div>
                                        <div className="socialinfoteam">
                                        <div className="teamfaX" > 
                                        <img alt = "" src = "twitter.png" height="20px" width="20px"/>
                                        </div> 
                                        <div className="teamfalinkdin">
                                        <img alt ="" src ="linkedin.png" height="20px" width="20px" />
                                        </div>
                                        <div className="teamfaemail">
                                        <img alt= "" src ="mail.png"  height="20px" width="20px"/>
                                        </div>
                                        </div>
                                        </div>
                        </div> <div className="teammember1">
                                        <img className="teamuserpicIcon" alt="" src="/review10.jpg" />
                                        <div className="teammemberdetails">
                                        <div className="teammembername">Archit</div>
                                        <div className="teamrole">SDE Intern</div>
                                        <div className="socialinfoteam">
                                        <div className="teamfaX" > 
                                        <img alt = "" src = "twitter.png" height="20px" width="20px"/>
                                        </div> 
                                        <div className="teamfalinkdin">
                                        <a href="https://www.linkedin.com/in/archityadav24092002/" target="_blank" rel="noopener noreferrer">
                                        <img alt="" src="linkedin.png" height="20px" width="20px" />
                                        </a>
                                       </div>
                                        <div className="teamfaemail">
                                        <img alt= "" src ="mail.png"  height="20px" width="20px"/>
                                        </div>
                                        </div>
                                        </div>
                        </div> <div className="teammember1">
                                        <img className="teamuserpicIcon" alt="" src="/review7.jpg" />
                                        <div className="teammemberdetails">
                                        <div className="teammembername">Hannah</div>
                                        <div className="teamrole">Commercial Advisor</div>
                                        <div className="socialinfoteam">
                                        <div className="teamfaX" > 
                                        <img alt = "" src = "twitter.png" height="20px" width="20px"/>
                                        </div> 
                                        <div className="teamfalinkdin">
                                        <img alt ="" src ="linkedin.png" height="20px" width="20px" />
                                        </div>
                                        <div className="teamfaemail">
                                        <img alt= "" src ="mail.png"  height="20px" width="20px"/>
                                        </div>
                                        </div>
                                        </div>
                        </div> <div className="teammember1">
                                        <img className="teamuserpicIcon" alt="" src="/review8.png" />
                                        <div className="teammemberdetails">
                                        <div className="teammembername">Liam</div>
                                        <div className="teamrole">Technical Advisor</div>
                                        <div className="socialinfoteam">
                                        <div className="teamfaX" > 
                                        <img alt = "" src = "twitter.png" height="20px" width="20px"/>
                                        </div> 
                                        <div className="teamfalinkdin">
                                        <img alt ="" src ="linkedin.png" height="20px" width="20px" />
                                        </div>
                                        <div className="teamfaemail">
                                        <img alt= "" src ="mail.png"  height="20px" width="20px"/>
                                        </div>
                                        </div>
                                        </div>
                  </div>
                  </div>
                  </div>);
 }
 

export default aboutteam
