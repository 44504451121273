import React from 'react'
import { useState } from 'react';

const Synergydatatile5 = () => {
  const locations = ['London', 'Manchester', 'Birmingham','Liverpool','Yorkshire','EdinBurgh'];
 // const locations = ['London', 'Manchester', 'Glasgow','Birmingham','Brighton','Leeds','Liverpool','Yorkshire','Bath','EdinBurgh'];
    const [selectedOptionslocation, setSelectedOptionslocation] = useState([]);
  
    const handleCheckboxChangelocation = (location) => {
      if (selectedOptionslocation.includes(location)) {
        setSelectedOptionslocation(selectedOptionslocation.filter((selected) => selected !== location));
      } else {
        setSelectedOptionslocation([...selectedOptionslocation, location]);
      }
    };
 //   console.log(selectedOptionslocation)
    //<p>Selected Options: {selectedOptionslocation.join(', ')}</p>
  return (
    <div className='synergydatatile1'>

      <div className="synergydataleft">
        <img className='Sidebar5' src = "../synergy/Sidebar5.svg" alt = " " />
       </div>
    
      <div className="synergydataright">
          <div className="arrayofline">
          <div className="line1synergyyes"> </div>
          <div className="line1synergyyes"> </div>
          <div className="line1synergyyes"> </div>
          <div className="line1synergyyes"> </div>
          <div className="line1synergyyes"> </div>
          </div>

        <div className="synergyrightfind">FIND YOUR SYNERGY</div>
        <div className="synergytell">What is your preferred location preferences ?</div>
       


                   <div className='fourtsynergyoption'>
                   {locations.map((location, index) => (
                   <label
                   key={index}
                   className={`checkbox-container ${selectedOptionslocation.includes(location) ? 'selected' : ''}`}
                   style={{ display: 'flex', marginLeft: "20px" }}
                   >
                      <input
                           type="checkbox"
                           style={{ marginRight: '5px', borderRadius: '50%' }}
                           checked={selectedOptionslocation.includes(location)}
                           onChange={() => handleCheckboxChangelocation(location)}
                       />
                   {location}
                   </label>
                   ))}
 
</div>
</div>
    </div>
  )
}

export default Synergydatatile5
