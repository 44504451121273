import React from 'react'
import "./aboutbuilding.scss"

const aboutbuilding = () => {
  return (
    <div  className='aboutbuilding'> 
       
    <div className="building">Building the future of Retail</div>
    <div className="marketplace">intelligent SaaS-enabled marketplace</div> 


    </div>
  )
}

export default aboutbuilding
